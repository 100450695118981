import React, {
  useCallback,
  useContext,
  useMemo,
  // useRef,
  useState,
} from "react";
import styled from "styled-components/native";
import HeaderText from "@app/components/questkit/headerText";
import TextInput from "@app/components/questkit/textInput";
import Text from "@app/components/questkit/text";
import { Platform, View } from "react-native";
import {
  SnackbarContext,
  SnackbarSeverity,
} from "@app/components/snackbar/SnackbarContext";
import { updateQuestInstance } from "@app/util/client/requests/quests";
// import { ReviewerPickerController } from "@app/components/questkit/ReviewerPicker";
import DueDate, { DueDateOptions } from "./dueDateOptions";
import {
  ChangeSet,
  extractUnsavedFields,
  useChangeTracker,
} from "@app/quest/edit/useChangeTracker";
import Button from "@app/components/questkit/button";
import { Analytics } from "@app/analytics";
import { usePromise } from "@app/util/usePromise";
import { useAppNavigation } from "@app/navigation/QMNavigator";
import { sentry } from "@app/util/sentry";
import { useAppSelector } from "@app/store";
import { selectQuestInstanceById } from "@app/store/cache/questInstances";
import { selectReviewerById } from "@app/store/cache/reviewers";
import { MODAL_CLOSE_DELAY } from "@app/components/modal";
import { selectQuestStartConfigurationById } from "@app/store/cache/questStartConfigurations";
import isEqual from "react-fast-compare";
import { addSeconds, differenceInSeconds } from "date-fns";
import PressableOpacity from "@app/components/questkit/PressableOpacity";

interface QuestRunOptionsDialogProps {
  questInstanceId: string;
  setShowOptionsModal: (showOptionsModal: boolean) => void;
}

const QuestRunOptionsDialog: React.FC<QuestRunOptionsDialogProps> = ({
  questInstanceId,
  setShowOptionsModal,
}) => {
  const snackbarContext = useContext(SnackbarContext);
  const navigation = useAppNavigation();

  const questInstanceFromServer = useAppSelector((state) => {
    const instance = selectQuestInstanceById(state, questInstanceId)!;
    if (!instance) {
      return;
    }
    let startConfig;
    if (instance) {
      if (instance?.startConfigurationId) {
        startConfig = selectQuestStartConfigurationById(
          state,
          instance.startConfigurationId
        );
      }
      if (!startConfig) {
        const error = new Error(
          "Unexpected: Start Configuration not found in cache."
        );
        sentry.captureException(error, {
          extra: {
            instance,
            questInstanceId: questInstanceId,
            questPrototype: instance,
            startConfigurationsInCache: state.cache.questStartConfigurations,
          },
        });
      }
    }

    return {
      name: instance.name,
      questId: instance.questId,
      status: instance.status,
      archived: instance.archived,
      dueAt: instance.dueAt,
      // dueAfterSeconds: startConfig?.dueAfterSeconds,
      allowOverdueSubmissions: instance.allowOverdueSubmissions,
      remindAt: instance.remindAt,
      alertAt: instance.alertAt,

      startConfigurationId: instance.startConfigurationId,
      reviewers:
        startConfig?.reviewerIds?.map((id) => {
          return { id, userId: selectReviewerById(state, id)!.userId };
        }) ?? [],
    };
  }, isEqual);

  const { useValueWithChanges, addChange, getChangeSet } = useChangeTracker(
    questInstanceFromServer
  );
  const questInstance = useValueWithChanges();

  const [saveSuccessful, setSaveSuccessful] = useState(false);

  const isSavingReviewers = false;
  // const { execute: saveReviewers, isLoading: isSavingReviewers } = usePromise(
  //   async (newReviewers: { id: string }[]) => {
  //     return updateQuestReviewers(
  //       questInstanceId,
  //       questInstance!.reviewers,
  //       newReviewers
  //     )
  //       .then((result) => {
  //         if (result.errors.length > 0) {
  //           sentry.addBreadcrumb({
  //             message: "Errors saving reviewers",
  //             data: { errors: result.errors },
  //           });
  //           throw new Error("Failed to save reviewers");
  //         }
  //       })
  //       .catch((e) => {
  //         snackbarContext.sendMessage(
  //           `We unfortunately couldn't save your reviewers. Please try again later.`,
  //           SnackbarSeverity.WARNING
  //         );
  //         sentry.captureException(e);
  //         throw e;
  //       });
  //   }
  // );
  const { execute: saveQuestInstance, isLoading: isSavingQuestInstance } =
    usePromise(
      async (
        changeSet: ChangeSet<Exclude<typeof questInstance, undefined>>
      ) => {
        if (changeSet.hasUnsavedChanges) {
          const unsavedFields = extractUnsavedFields(changeSet, [
            ["name"],
            ["dueAt"],
            ["allowOverdueSubmissions"],
            ["remindAt"],
            ["alertAt"],
          ]);

          // const { valueWithChanges: changedQuestInstance } = changeSet;
          // const dueDateSet = changedQuestInstance.dueAt;

          changeSet.markPending();
          return updateQuestInstance(questInstanceId, {
            ...unsavedFields,
            // ...(dueDateSet
            //   ? {}
            //   : {
            //       remindAt: undefined,
            //       alertAt: undefined,
            //     }),
          })
            .then((_res) => {
              changeSet.markSaved();
            })
            .catch((err) => {
              changeSet.markUnsaved();
              snackbarContext.sendMessage(
                `We unfortunately couldn't save. Please try again later.`,
                SnackbarSeverity.WARNING
              );
              console.error("Error saving quest options:", err);
              sentry.captureException(err);
              throw err;
            });
        }
      }
    );

  const onSave = useCallback(async () => {
    Analytics.trackEvent("Save Quest Options");

    const changeSet = getChangeSet() as ChangeSet<
      Exclude<typeof questInstance, undefined>
    >;
    const changedQuestInstance = changeSet.valueWithChanges;

    // const reviewerPicker = reviewerPickerRef.current!;
    // const reviewers = reviewerPicker.requiresReview
    //   ? await reviewerPicker.userPickerRef.current!.flush()
    //   : [];
    // if (reviewerPicker.requiresReview && reviewers.length === 0) {
    //   snackbarContext.sendMessage(
    //     `Must set at least one reviewer when submissions require review.`,
    //     SnackbarSeverity.WARNING
    //   );
    //   return;
    // } else
    if (!changedQuestInstance.name?.trim()) {
      snackbarContext.sendMessage(
        `Please enter a name for the Quest.`,
        SnackbarSeverity.WARNING
      );
      return;
    }

    Promise.all([
      // saveReviewers(reviewers),
      saveQuestInstance(changeSet),
    ])
      .then(() => {
        setSaveSuccessful(true);
        setTimeout(() => setShowOptionsModal(false), MODAL_CLOSE_DELAY);
      })
      .catch(() => {
        // errors handled above
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questInstanceId]);

  const [updateArchiveStateSuccessful, setUpdateArchiveStateSuccessful] =
    useState(false);
  const { execute: updateArchiveStatus, isLoading: isSavingArchiveStatus } =
    usePromise(async () => {
      if (!questInstance) {
        return;
      }
      setUpdateArchiveStateSuccessful(false);
      const newArchived = !questInstance.archived;
      try {
        await updateQuestInstance(questInstanceId, {
          archived: newArchived,
        });

        setUpdateArchiveStateSuccessful(true);
        setTimeout(() => {
          if (newArchived) {
            navigation.navigate("Quest", {
              questId: questInstance.questId!,
              screen: "QuestRuns",
            });
          }
          setShowOptionsModal(false);
        }, MODAL_CLOSE_DELAY);
      } catch (e) {
        console.log("Error changing run archive status", e);
        snackbarContext.sendMessage(
          `We unfortunately couldn't ${
            newArchived ? "archive" : "unarchive"
          } "${questInstance.name}". Please try again later.`,
          SnackbarSeverity.WARNING
        );
        sentry.captureException(e);
      }
    });

  // const reviewerPickerRef = useRef<ReviewerPickerController>(null);

  const isSaving =
    isSavingQuestInstance || isSavingReviewers || isSavingArchiveStatus;

  // const isCompleted = questInstance?.status === "COMPLETED";

  const onChangeText = useCallback(
    (name: string) => addChange((draft) => (draft.name = name)),
    [addChange]
  );

  const dueDateOptions = useMemo((): DueDateOptions => {
    if (!questInstance) {
      return {
        dueAt: null,
        dueAfterSeconds: null,
        remindBeforeSeconds: null,
        alertBeforeSeconds: null,
        allowOverdueSubmissions: false,
      };
    }
    let remindBeforeSeconds = null;
    if (questInstance.dueAt && questInstance.remindAt) {
      remindBeforeSeconds = differenceInSeconds(
        new Date(questInstance.dueAt),
        new Date(questInstance.remindAt)
      );
    }

    let alertBeforeSeconds = null;
    if (questInstance.dueAt && questInstance.alertAt) {
      alertBeforeSeconds = differenceInSeconds(
        new Date(questInstance.dueAt),
        new Date(questInstance.alertAt)
      );
    }

    return {
      dueAt: questInstance.dueAt,
      dueAfterSeconds: null,
      remindBeforeSeconds: remindBeforeSeconds,
      alertBeforeSeconds: alertBeforeSeconds,
      allowOverdueSubmissions: questInstance.allowOverdueSubmissions,
    };
  }, [questInstance]);

  const onDueDateOptionsChange = useCallback(
    (options: DueDateOptions) => {
      const {
        dueAt,
        remindBeforeSeconds,
        alertBeforeSeconds,
        allowOverdueSubmissions,
      } = options;

      addChange((draft) => {
        if (!dueAt) {
          draft.dueAt = null;
          draft.remindAt = null;
          draft.alertAt = null;
          draft.allowOverdueSubmissions = allowOverdueSubmissions;
        } else {
          const dueAtDate = new Date(dueAt);
          let remindAt = null;
          if (remindBeforeSeconds !== null) {
            remindAt = addSeconds(
              dueAtDate,
              -remindBeforeSeconds
            ).toISOString();
          }
          let alertAt = null;
          if (alertBeforeSeconds !== null) {
            alertAt = addSeconds(dueAtDate, -alertBeforeSeconds).toISOString();
          }

          draft.dueAt = dueAt;
          draft.remindAt = remindAt;
          draft.alertAt = alertAt;
          draft.allowOverdueSubmissions = allowOverdueSubmissions;
        }
      });
    },
    [addChange]
  );

  const preventUserInput =
    isSaving || saveSuccessful || updateArchiveStateSuccessful;

  return !questInstance ? null : (
    <OptionsDialogScrollView keyboardShouldPersistTaps="always">
      <View onStartShouldSetResponder={() => true}>
        <Section>
          <HeaderText title="Quest Name" />
          <TextInput
            onChangeText={onChangeText}
            value={questInstance.name}
            placeholder={"Awesome Quest"}
            editable={!preventUserInput}
          />
          {questInstance.dueAt !== undefined ? (
            <OptionsContainer>
              <HeaderText title="Options" />
              <DueDate
                options={dueDateOptions}
                disableRelativeDueDate={true}
                readOnly={preventUserInput}
                readOnlyMessage="You can't change the due date of a completed Quest."
                onChange={onDueDateOptionsChange}
              />
            </OptionsContainer>
          ) : null}
        </Section>
        {/*<Section>*/}
        {/*  <HeaderText title="Reviewers" />*/}
        {/*  <ReviewerPicker*/}
        {/*    questPrototypeId={questInstanceId}*/}
        {/*    startConfigurationId={questInstance.startConfigurationId!}*/}
        {/*    ref={reviewerPickerRef}*/}
        {/*    readOnly={isCompleted}*/}
        {/*    readOnlyMessage="You can't add reviewers to a completed Quest."*/}
        {/*  />*/}
        {/*</Section>*/}

        <StyledOptionsDialogButton
          onPress={onSave}
          success={saveSuccessful || updateArchiveStateSuccessful}
          loading={isSaving}
          title="Save"
        />

        <PressableOpacity
          activeOpacity={0.8}
          onPress={updateArchiveStatus}
          disabled={preventUserInput}
        >
          <ArchiveText
            size="medium"
            hide={isSavingArchiveStatus || updateArchiveStateSuccessful}
          >
            {questInstance.archived ? "Unarchive Run" : "Archive Run"}
          </ArchiveText>
        </PressableOpacity>
      </View>
    </OptionsDialogScrollView>
  );
};

const Section = styled.View`
  margin-bottom: 40px;
`;

const OptionsDialogScrollView = styled.ScrollView.attrs({
  contentContainerStyle: {
    paddingHorizontal: 12,
    paddingVertical: 10,
  },
})``;

const OptionsContainer = styled.View`
  margin-top: 40px;
`;

const ArchiveText = styled(Text)<{ hide: boolean }>`
  text-decoration-line: ${({ hide }) => (hide ? "none" : "underline")};
  opacity: ${({ hide }) => (hide ? 0 : 1)};
  align-self: center;
  margin-top: 0;
  margin-bottom: 14px;
  ${Platform.OS === "web"
    ? `
  user-select: none;
  `
    : ""}
`;

const StyledOptionsDialogButton = styled(Button)`
  width: 100%;
  align-self: center;
  margin-bottom: 24px;
`;

export default QuestRunOptionsDialog;
