import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { Keyboard, useWindowDimensions } from "react-native";
import { useAppSelector } from "@app/store";
import { isDesktopWidth } from "@app/themes/Breakpoints";
import { navigationRef } from "@app/navigation/QMNavigationContainer";
import findFocusedRoute from "@react-navigation/core/src/findFocusedRoute";
import { type CombinedRoutesParamList } from "@app/navigation/QMNavigator";
import { useEffectOnce } from "@app/util/useEffectOnce";
import { useStateWithRef } from "@app/components/questkit/useStateWithRef";
import { getLoggedInUserId } from "@app/util/getLoggedInUserId";
import { ArrayElement } from "@questmate/common";
import {
  SharedValue,
  useSharedValue,
  withTiming,
} from "react-native-reanimated";

const SCREENS_SIDE_BAR_SHOULD_NOT_SHOW: Array<keyof CombinedRoutesParamList> = [
  "CreateQuestLoading",
  "PublicAssignment",
  "QuestInstance",
  "QuestEdit",
  "Logout",
];

export const SideBarProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const dimensions = useWindowDimensions();
  const isDesktop = isDesktopWidth(dimensions);

  const [drawerOpen, _setDrawerOpen, drawerOpenRef] = useStateWithRef(false);
  const openDrawer = useCallback(() => {
    Keyboard.dismiss();
    _setDrawerOpen(true);
  }, [_setDrawerOpen]);
  const closeDrawer = useCallback(() => {
    _setDrawerOpen(false);
  }, [_setDrawerOpen]);

  const userIsLoggedIn = useAppSelector((state) =>
    Boolean(state.auth.loggedInUserId)
  );
  const drawerShouldBeOpen = drawerOpen && userIsLoggedIn;

  const isFirstStateUpdate = useRef(true);
  useEffectOnce(() => {
    const listener = () => {
      if (isDesktopWidth()) {
        const shouldBeOpen = shouldBeOpenOnDesktop();

        if (isFirstStateUpdate.current && shouldBeOpen) {
          // do not animate the sidebar opening when first loading the app on desktop
          drawerSlideAnimation.value = 1;
          paddingAnimation.value = 1;
        }

        if (drawerOpenRef.current !== shouldBeOpen) {
          _setDrawerOpen(shouldBeOpen);
        }
      } else {
        closeDrawer();
      }
      isFirstStateUpdate.current = false;
    };

    navigationRef.addListener("state", listener);
    return () => {
      navigationRef.removeListener("state", listener);
    };
  });

  // close or open sidebar when changing from desktop to mobile or vice versa
  const isDesktopRef = useRef(isDesktop);
  useEffect(() => {
    const previousIsDesktop = isDesktopRef.current;
    isDesktopRef.current = isDesktop;

    if (isDesktop !== previousIsDesktop) {
      if (isDesktop) {
        const shouldBeOpen = shouldBeOpenOnDesktop();
        if (drawerOpenRef.current !== shouldBeOpen) {
          _setDrawerOpen(shouldBeOpen);
        }
      } else {
        if (drawerOpenRef.current) {
          closeDrawer();
        }
      }
    }
  }, [_setDrawerOpen, closeDrawer, drawerOpenRef, isDesktop]);

  const drawerSlideAnimation = useSharedValue(0);
  const overlayOpacityAnimation = useSharedValue(0);
  const paddingAnimation = useSharedValue(0);

  useEffect(() => {
    // Split into 3 animations to avoid a bug where the opacity and padding would not correctly
    // switch from the animated value to a constant when rotating the device on iPad simulator.
    const sharedAnimationConfig = {
      duration: 300,
    };
    drawerSlideAnimation.value = withTiming(drawerShouldBeOpen ? 1 : 0, {
      ...sharedAnimationConfig,
    });
    overlayOpacityAnimation.value = withTiming(
      drawerShouldBeOpen && !isDesktop ? 1 : 0,
      {
        ...sharedAnimationConfig,
      }
    );
    paddingAnimation.value = withTiming(
      drawerShouldBeOpen && isDesktop ? 1 : 0,
      {
        ...sharedAnimationConfig,
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerShouldBeOpen, isDesktop]);

  const sideBarWidth = Math.min(320, dimensions.width * 0.9);

  const context = useMemo(
    () => ({
      sideBarWidth,
      drawerSlideAnimation,
      overlayOpacityAnimation,
      paddingAnimation,
      openDrawer,
      closeDrawer,
    }),
    [
      closeDrawer,
      drawerSlideAnimation,
      openDrawer,
      overlayOpacityAnimation,
      paddingAnimation,
      sideBarWidth,
    ]
  );
  return (
    <SideBarContext.Provider value={context}>
      {children}
    </SideBarContext.Provider>
  );
};

interface SideBarContext {
  drawerSlideAnimation: SharedValue<number>;
  overlayOpacityAnimation: SharedValue<number>;
  paddingAnimation: SharedValue<number>;
  sideBarWidth: number;
  openDrawer: () => void;
  closeDrawer: () => void;
}

const SideBarContext = React.createContext<SideBarContext>({
  drawerSlideAnimation: { value: 0 },
  overlayOpacityAnimation: { value: 0 },
  paddingAnimation: { value: 0 },
  sideBarWidth: 0,
  openDrawer: () => undefined,
  closeDrawer: () => undefined,
});

const shouldBeOpenOnDesktop = () =>
  Boolean(getLoggedInUserId()) &&
  !SCREENS_SIDE_BAR_SHOULD_NOT_SHOW.includes(
    findFocusedRoute(navigationRef.getRootState())?.name as ArrayElement<
      typeof SCREENS_SIDE_BAR_SHOULD_NOT_SHOW
    >
  );

export const useSideBarContext = (): SideBarContext => {
  const sideBarContext = React.useContext(SideBarContext);
  if (!sideBarContext) {
    throw new Error("useSideBarContext must be used within a SideBarContext");
  }
  return sideBarContext;
};
