import * as React from "react";
import { useEffect, useMemo } from "react";
import {
  Keyboard,
  Platform,
  StyleProp,
  StyleSheet,
  ViewStyle,
} from "react-native";
import {
  createNavigatorFactory,
  EventMapBase,
  PartialRoute,
  Route,
  TabActions,
  TabRouter,
  useNavigationBuilder,
} from "@react-navigation/native";
import { EventEmitter } from "@react-navigation/core/src/types";
import Icon, { IconIdentifier } from "@app/components/icon";
import { navigationRef } from "@app/navigation/QMNavigationContainer";
import { NavigationState, PartialState } from "@react-navigation/routers";
import {
  getHrefForInternalLink,
  LinkableLocation,
  OnLinkPress,
} from "@app/util/link.utils";
import styled from "styled-components/native";
import BasePressable from "@app/components/questkit/BasePressable";
import Text from "@app/components/questkit/text";
import isEqual from "react-fast-compare";
import {
  StackNavigationOptions,
  StackNavigationProp,
} from "@react-navigation/stack";
import type { CombinedRoutesParamList } from "@app/navigation/QMNavigator";
import { useIsEqualMemo } from "@app/util/useIsEqualMemo";

interface StandardScreenOptions {
  title: string;
  tabTitle: string;
  tabTitleIcon?: IconIdentifier;
  hideTabBar?: false;
  testID?: string;
  parentScreenOptions?: Partial<StackNavigationOptions>;
}
interface HiddenTabScreenOptions {
  title: string;
  hideTabBar: true;
  parentScreenOptions?: Partial<StackNavigationOptions>;
}

export type TabScreenOptions = StandardScreenOptions | HiddenTabScreenOptions;

interface TabNavigatorProps {
  initialRouteName: string;
  children: React.ReactElement | null;
  screenOptions: TabScreenOptions;
  tabBarStyle?: StyleProp<ViewStyle>;
  contentStyle?: StyleProp<ViewStyle>;
}

interface RouteProps {
  onPress: OnLinkPress;
}

function TabNavigator({
  initialRouteName,
  children,
  screenOptions,
  tabBarStyle,
  contentStyle,
}: TabNavigatorProps) {
  const { state, navigation, descriptors, NavigationContent } =
    useNavigationBuilder(TabRouter, {
      children,
      screenOptions,
      initialRouteName,
    });

  const routePropsByKey = useMemo(() => {
    const routesToState = findRouteToState(
      navigationRef.getRootState(),
      state.key
    );

    return state.routes.reduce((acc, route) => {
      const onPress = (pressEvent: Parameters<OnLinkPress>[0]) => {
        const event = (
          navigation as EventEmitter<{
            tabPress: {
              canPreventDefault: true;
            };
          }>
        ).emit({
          type: "tabPress",
          target: route.key,
          canPreventDefault: true,
        });

        if (!event.defaultPrevented) {
          Keyboard.dismiss();
          navigation.dispatch({
            ...TabActions.jumpTo(route.name),
            target: state.key,
          });
          pressEvent?.preventDefault();
          return false;
        }
      };
      let href;
      if (routesToState) {
        href = getHrefForInternalLink({
          screen: routesToState[0].name,
          params: {
            ...routesToState[0].params,
            screen: route.name,
          },
        } as LinkableLocation);
      }

      onPress.linkProps = {
        accessibilityRole: "link" as const,
        href,
      };

      acc[route.key] = { onPress };
      return acc;
    }, {} as Record<string, RouteProps>);
  }, [navigation, state.key, state.routes]);

  const activeScreenOptions = descriptors[state.routes[state.index].key]
    .options as TabNavigatorProps["screenOptions"] & {
    headerLeft: unknown;
    headerRight: unknown;
  };
  const { hideTabBar, parentScreenOptions: _parentScreenOptions } =
    activeScreenOptions;

  const parentScreenOptions = useIsEqualMemo(_parentScreenOptions ?? {});

  useEffect(() => {
    (
      navigation.getParent() as StackNavigationProp<
        CombinedRoutesParamList,
        "Quest"
      >
    )?.setOptions(parentScreenOptions);
  }, [navigation, parentScreenOptions]);

  // TODO: Animate (smoothly)
  return (
    <NavigationContent>
      {hideTabBar ? null : (
        <TabBar style={[tabBarStyle]} accessibilityRole={"tablist"}>
          {state.routes
            .filter((route) => !descriptors[route.key].options.hideTabBar)
            .map((route) => {
              const isActiveRoute = state.routes[state.index].key === route.key;

              const screenOptions = descriptors[route.key]
                .options as StandardScreenOptions;
              return (
                <TabButton
                  key={route.key}
                  active={isActiveRoute}
                  disabled={hideTabBar}
                  title={screenOptions.tabTitle || route.name}
                  titleIcon={screenOptions.tabTitleIcon}
                  testID={screenOptions.testID}
                  onPress={routePropsByKey[route.key].onPress}
                />
              );
            })}
        </TabBar>
      )}
      <TabContentWrapper style={[contentStyle]}>
        {state.routes.map((route, index) => (
          <TabViewWrapper key={route.key} isActiveRoute={index === state.index}>
            {descriptors[route.key].render()}
          </TabViewWrapper>
        ))}
      </TabContentWrapper>
    </NavigationContent>
  );
}

const TabContentWrapper = styled.View`
  flex: 1;
`;

const TabViewWrapper = styled.View<{ isActiveRoute: boolean }>`
  flex: 1;
  display: ${({ isActiveRoute }) => (isActiveRoute ? undefined : "none")};
`;

// This feels silly. We just need to know the parent route(s) to determine the path to the tab route.
function findRouteToState(
  state: NavigationState | PartialState<NavigationState> | undefined,
  navigationKey: string,
  routes: (Route<string> | PartialRoute<Route<string>>)[] = []
): PartialRoute<Route<string>>[] | null {
  if (state?.key === navigationKey) {
    return routes;
  }
  if (Array.isArray(state?.routes)) {
    for (const route of state!.routes) {
      if (route.state) {
        const routeToState = findRouteToState(route.state, navigationKey, [
          ...routes,
          route,
        ]);
        if (routeToState) {
          return routeToState;
        }
      }
    }
  }
  return null;
}

export const createTabNavigator = createNavigatorFactory<
  NavigationState,
  TabNavigatorProps["screenOptions"],
  EventMapBase,
  typeof TabNavigator
>(TabNavigator);

const TabButton: React.FC<{
  disabled?: boolean;
  title: string;
  titleIcon?: IconIdentifier;
  active: boolean;
  onPress: OnLinkPress | (() => void);
  testID?: string;
}> = React.memo(({ disabled, title, titleIcon, active, onPress, testID }) => {
  return (
    <TabBarButtonPressable
      onPress={onPress}
      testID={testID}
      disabled={disabled}
      active={active}
      accessibilityRole={"tab"}
    >
      {titleIcon ? (
        <IconWrapper>
          <StyledIcon icon={titleIcon} active={active} container="COLLAPSED" />
        </IconWrapper>
      ) : null}
      <TabBarItemText size={"small"} active={active}>
        {title}
      </TabBarItemText>
    </TabBarButtonPressable>
  );
}, isEqual);
TabButton.displayName = "TabBarItem";

const TabBar = styled.View`
  flex-direction: row;
  border-width: ${StyleSheet.hairlineWidth}px;
  border-color: ${({ theme }) => theme.textInput.normal.border};
  padding: 8px;
  gap: 8px;
  border-radius: 57px;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin-bottom: 4px;
`;

const TabBarButtonPressable = styled(BasePressable)<{ active: boolean }>`
  height: 40px;
  width: 148px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background-color: ${({ theme, active }) =>
    active
      ? theme.button.primary.background
      : theme.tile.standard.background.normal};
`;

const StyledIcon = styled(Icon)<{ active: boolean }>`
  color: ${({ theme, active }) =>
    active ? theme.button.primary.text : theme.textInput.normal.text};
`;

const IconWrapper = styled.View`
  justify-content: center;
  align-items: center;
  margin-right: 4px;
`;

const TabBarItemText = styled(Text)<{ active: boolean }>`
  ${Platform.OS === "web" ? "user-select: none;" : ""}
  color: ${({ theme, active }) =>
    active ? theme.button.primary.text : theme.textInput.normal.text};
`;
