import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  KeyboardAvoidingView,
  Platform,
  SectionListRenderItemInfo,
  View,
} from "react-native";
import SafeAreaView from "react-native-safe-area-view";

import styled from "styled-components/native";

import { useAppNavigation, useAppRoute } from "@app/navigation/QMNavigator";
import { RevealText } from "@app/components/questkit/revealText";
import {
  ChooseEntry,
  ChooseEntryProps,
  FilterConfig,
  SearchWithTabsAndFilters,
  SectionData,
} from "@app/components/modal/chooseEntry";
import { Filter, FiltersProps, FilterTypes } from "@app/components/filters";
import { Quests } from "@questmate/openapi-spec";
import { QuestTabsParamList } from "@app/quest/MainQuestScreen";
import { useIsEqualMemo } from "@app/util/useIsEqualMemo";
import { useQuestContext } from "@app/quest/QuestContext";
import { createLink } from "@app/util/link.utils";
import { OmnipresentStartButton } from "@app/components/screen/quest/common/OmnipresentStartButton";
import { AppState, store, useAppDispatch, useAppSelector } from "@app/store";
import { selectQuestById } from "@app/store/cache/quests";
import { selectQuestPrototypeById } from "@app/store/cache/questPrototypes";
import { Boundary } from "@app/components/screen/boundary";
import useSWRInfinite from "swr/infinite";
import {
  questInstanceListLoaded,
  selectQuestInstanceById,
} from "@app/store/cache/questInstances";
import { selectItemInstanceByComboId } from "@app/store/cache/itemInstances";
import { selectAssignmentById } from "@app/store/cache/assignments";
import { selectUserById } from "@app/store/cache/users";
import {
  createSearchFilter,
  createSearchTerm,
} from "@app/components/questkit/searchFilter";
import {
  QuestRunListItem,
  QuestRunListItemData,
  RunListItemMetadata,
} from "@app/quest/run/QuestRunListItem";
import { IconIdentifier } from "@app/components/icon";
import { setQuestRunFilters } from "@app/store/UI";
import { InlineErrorWithRetry } from "@app/components/item/components/custom/InlineErrorWithRetry";
import { SnackbarContext } from "@app/components/snackbar/SnackbarContext";
import { useStateWithRef } from "@app/components/questkit/useStateWithRef";
import { apiRequest, DEFAULT_TIMEOUT } from "@app/util/client";
import { RelativeDateText } from "@app/components/questkit/RelativeDateText";
import { DueDateBadge } from "@app/quest/run/DueDateBadge";
import Text from "@app/components/questkit/text";
import isEqual from "react-fast-compare";
import {
  NotUndefined,
  Overwrite,
  isNotUndefined,
  isTruthy,
} from "@questmate/common";
import { colors } from "@app/themes/Colors";
import { useRequest } from "@app/util/client/requests";
import { fetchScheduledQuestStarts } from "@app/util/client/requests/scheduledQuestStarts";
import { selectScheduledQuestStartsByQuestId } from "@app/store/cache/scheduledQuestStarts";
import { selectQuestStartConfigurationById } from "@app/store/cache/questStartConfigurations";
import { ScheduledQuestStartListItem } from "@app/quest/run/ScheduledQuestStartListItem";
import { ScreenContainer } from "@app/screens/ScreenContainer";

const emptyFilters: QuestTabsParamList["QuestRuns"]["filters"] = Object.freeze(
  {}
);

function useQuestRunPages(activeFilters: FilterIdsByGroupId, questId: string) {
  const getQuestRunsRequestKey = (
    pageIndex: number,
    previousPageData: Quests.RunsDetail.ResponseBody | undefined
  ) => {
    // reached the end
    if (previousPageData && !previousPageData.nextCursor) {
      return null;
    }
    const params = new URLSearchParams({
      limit: pageIndex === 0 ? "20" : "1000",
    });

    activeFilters.status?.forEach((status) => {
      params.append("status[]", status);
    });

    if (activeFilters.archived?.[0]) {
      params.append("archived", activeFilters.archived[0]);
    }

    if (previousPageData && pageIndex !== 0) {
      params.append("cursor", previousPageData.nextCursor!);
    }
    return ["get", `/quests/${questId}/runs?${params.toString()}`];
  };

  const {
    data: _questRunPages,
    error: questRunsError,
    mutate: questRunsMutate,
    isValidating: isLoadingARunPage,
    size,
    setSize,
  } = useSWRInfinite<Omit<Quests.RunsDetail.ResponseBody, "data">>(
    getQuestRunsRequestKey,
    requestFn,
    {
      // avoid duplicate requests triggered by revalidateOnFocus on web
      revalidateOnMount: Platform.OS !== "web",
      // avoid repeatedly fetching the first page once for each other page fetched
      revalidateFirstPage: false,
      shouldRetryOnError: true,
      loadingTimeout: DEFAULT_TIMEOUT + 1000,
    }
  );
  const questRunPages = useIsEqualMemo(_questRunPages);
  useEffect(() => {
    // Immediately provide runs from the first page,
    // then continue to fetch pages until there is nothing more to fetch
    if (questRunPages && questRunPages.length <= size) {
      const hasMore = Boolean(
        questRunPages[questRunPages.length - 1].nextCursor
      );
      if (hasMore) {
        void setSize(questRunPages.length + 1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questRunPages]);

  const hasMoreRunPagesToLoad = Boolean(
    questRunPages?.[questRunPages.length - 1]?.nextCursor
  );
  const hasErrorLoadingRuns = !!questRunsError;

  const refreshRuns = useCallback(async () => {
    await questRunsMutate();
  }, [questRunsMutate]);

  return useMemo(
    () => ({
      refreshRuns,
      hasErrorLoadingRuns,
      isLoadingARunPage,
      hasMoreRunPagesToLoad,
    }),
    [hasMoreRunPagesToLoad, isLoadingARunPage, hasErrorLoadingRuns, refreshRuns]
  );
}

export const QuestRunsScreen: React.FC = () => {
  const { questId } = useQuestContext({ okIfNotReady: true });
  const navigation = useAppNavigation<"QuestRuns">();
  const snackbar = useContext(SnackbarContext);
  const dispatch = useAppDispatch();
  const savedFilters = useAppSelector(
    (state) => state.ui.questRunFilters[questId]
  );
  const activeFilters =
    useAppRoute<"QuestRuns">().params?.filters ?? savedFilters ?? emptyFilters;
  const activeFiltersRef = useRef(activeFilters);
  activeFiltersRef.current = activeFilters;
  const setActiveFilters = useCallback(
    (newActiveFilters: FilterIdsByGroupId) => {
      navigation.setParams({
        filters: newActiveFilters,
      });
      dispatch(setQuestRunFilters({ questId, filters: newActiveFilters }));
    },
    [dispatch, navigation, questId]
  );
  const filterProps = useFilters(
    RUN_FILTER_DEFINITIONS,
    activeFilters,
    setActiveFilters
  );

  const [searchText, setSearchText] = useState("");

  const questPrototype = useAppSelector((state) => {
    const quest = selectQuestById(state, questId);
    if (!quest || !quest.currentQuestPrototypeId) {
      return;
    }
    const qp = selectQuestPrototypeById(state, quest.currentQuestPrototypeId);
    if (!qp) {
      return;
    }

    return {
      id: qp.id,
      firstStartTriggerId: qp.startTriggerIds?.[0],
    };
  }, isEqual);
  const {
    hasErrorLoadingRuns,
    refreshRuns,
    isLoadingARunPage,
    hasMoreRunPagesToLoad,
  } = useQuestRunPages(activeFilters, questId);

  const {
    isLoading: isLoadingScheduledQuestStarts,
    refresh: refreshScheduledQuestStarts,
  } = useRequest(fetchScheduledQuestStarts(questId));

  const isLoadingRunPages =
    isLoadingARunPage || hasMoreRunPagesToLoad || isLoadingScheduledQuestStarts;

  const searchHasText = !!searchText?.trim();
  const searchCriteriaApplied =
    searchHasText ||
    activeFilters.status?.length ||
    activeFilters.archived?.[0] === "true";
  const placeholderText = hasErrorLoadingRuns
    ? "Oops, that didn't quite work."
    : isLoadingRunPages
    ? "Loading runs..."
    : searchCriteriaApplied
    ? "No Quest runs match your search criteria."
    : "Let's kick things off!";

  const runListPlaceholder = useCallback(() => {
    return (
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          paddingBottom: 110,
        }}
      >
        <QuestListPlaceholderTextWrapper>
          <RevealText text={placeholderText} />
        </QuestListPlaceholderTextWrapper>
      </View>
    );
  }, [placeholderText]) as unknown as React.ReactElement;

  const runListFooter = useMemo(() => {
    return (
      <SafeAreaView style={{ flex: 1 }} forceInset={{ bottom: "always" }} />
    );
  }, []);

  const renderItem = useCallback((info: SectionListRenderItemInfo<string>) => {
    return info.section.key === SCHEDULED_QUEST_STARTS_SECTION_KEY ? (
      <ScheduledQuestStartListItem scheduledQuestStartId={info.item} />
    ) : (
      <RunListItemContainer questInstanceId={info.item} />
    );
  }, []);

  const [, setRetryAttempts, retryAttemptsRef] = useStateWithRef(0);
  useEffect(() => {
    if (!hasErrorLoadingRuns && retryAttemptsRef.current > 0) {
      setRetryAttempts(0);
    }
  }, [hasErrorLoadingRuns, retryAttemptsRef, setRetryAttempts]);

  const retryLoadingRuns = useCallback(() => {
    if (
      retryAttemptsRef.current >= 3 &&
      Object.keys(activeFiltersRef.current).length !== 0
    ) {
      snackbar.sendMessage("Clearing search filters and retrying...");
      setActiveFilters({});
      setRetryAttempts(0);
    }
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        void refreshRuns().finally(() => {
          setRetryAttempts((prev) => prev + 1);
          resolve();
        });
      }, 0);
    });
  }, [
    refreshRuns,
    retryAttemptsRef,
    setActiveFilters,
    setRetryAttempts,
    snackbar,
  ]);
  const onPullToRefresh = useCallback(async () => {
    await Promise.all([refreshRuns(), refreshScheduledQuestStarts()]);
  }, [refreshScheduledQuestStarts, refreshRuns]);

  const showSpinnerInSearchbar = isLoadingRunPages && !hasErrorLoadingRuns;
  const searchAndFiltersComponent = useMemo(() => {
    return (
      <StyledSearchWithTabsAndFilters
        search={{
          searchPlaceholder: "Search Quest Runs",
          searchText: searchText,
          setSearchText: setSearchText,
        }}
        isLoading={showSpinnerInSearchbar}
        filters={filterProps}
      />
    );
  }, [filterProps, showSpinnerInSearchbar, searchText]);
  return (
    <ScreenContainer>
      <KeyboardAvoidingView
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        style={{ flex: 1 }}
      >
        <ListWidthContainer>
          {searchAndFiltersComponent}
          {hasErrorLoadingRuns ? (
            <InlineErrorWithRetry
              message={`We're having trouble loading your runs...`}
              isLoading={isLoadingARunPage}
              onRetry={retryLoadingRuns}
            />
          ) : null}
        </ListWidthContainer>
        <QuestRunList
          templateId={questId}
          activeFilters={activeFilters}
          searchText={searchText}
          placeholder={runListPlaceholder}
          renderItem={renderItem}
          footer={runListFooter}
          refreshData={onPullToRefresh}
          stickySectionHeadersEnabled={true}
        />
        {questPrototype?.firstStartTriggerId ? (
          <ActionButtonBottomPanel>
            <Boundary>
              <ActionButtonRow>
                <OmnipresentStartButton
                  questId={questId}
                  questPrototypeId={questPrototype.id}
                  startTriggerId={questPrototype.firstStartTriggerId}
                />
              </ActionButtonRow>
            </Boundary>
          </ActionButtonBottomPanel>
        ) : null}
      </KeyboardAvoidingView>
    </ScreenContainer>
  );
};

interface QuestRunListProps
  extends Omit<
    ChooseEntryProps<string>,
    "sectionsData" | "contentContainerStyle"
  > {
  templateId: string;
  activeFilters: FilterIdsByGroupId;
  searchText: string;
}

const SCHEDULED_QUEST_STARTS_SECTION_KEY = "SCHEDULED" as const;

const QuestRunList = React.memo((props: QuestRunListProps) => {
  const { templateId, activeFilters, searchText, ...chooseEntryProps } = props;
  const navigation = useAppNavigation();

  const scheduledQuestRunData = useAppSelector((state) => {
    if (
      (Array.isArray(activeFilters.status) &&
        !activeFilters.status.includes("OPEN")) ||
      activeFilters.archived?.[0] === "true"
    ) {
      return [];
    }

    const currentQuestPrototypeId = selectQuestById(
      state,
      templateId
    )?.currentQuestPrototypeId;
    const questName = currentQuestPrototypeId
      ? selectQuestPrototypeById(state, currentQuestPrototypeId)?.name
      : "";

    return selectScheduledQuestStartsByQuestId(state, templateId).map(
      (scheduledStart) => {
        const name = scheduledStart.runName ?? questName;
        const startConfiguration = selectQuestStartConfigurationById(
          state,
          scheduledStart.startConfigurationId
        );
        const assigneeNames = startConfiguration.assignmentIds
          .map((assignmentId) => {
            const assigneeId = selectAssignmentById(
              state,
              assignmentId
            )?.assigneeId;
            if (!assigneeId) {
              return;
            }
            const assignee = selectUserById(state, assigneeId);
            if (!assignee) {
              return;
            }
            return assignee.displayName;
          })
          .filter(isNotUndefined);
        return {
          id: scheduledStart.id,
          sectionKey: SCHEDULED_QUEST_STARTS_SECTION_KEY,
          searchTerms: [
            createSearchTerm(name),
            ...(scheduledStart.runExternalId
              ? [createSearchTerm(scheduledStart.runExternalId)]
              : []),
            ...assigneeNames.map((assigneeName) =>
              createSearchTerm(assigneeName)
            ),
          ],
        };
      }
    );
  }, isEqual);

  // const start = Date.now();
  const lastQuestRunDataRef = useRef(
    [] as ReturnType<typeof selectFilteredQuestRuns>
  );
  const questRunData = useAppSelector(
    (state) => {
      if (!navigation.isFocused()) {
        // Avoid the cost of re-filtering runs when the screen is not focused.
        return lastQuestRunDataRef.current;
      }
      return selectFilteredQuestRuns(state, templateId, activeFilters);
    },
    (a, b) => {
      // shallow compare the arrays to save performance as the view model will not change unless its values change
      if (a === b) {
        return true;
      }
      if (a.length !== b.length) {
        return false;
      }
      for (let i = 0; i < a.length; i++) {
        if (a[i] !== b[i]) {
          return false;
        }
      }
      return true;
    }
  );
  lastQuestRunDataRef.current = questRunData;
  // const s1 = Date.now();

  const combinedQuestRunData = useMemo(() => {
    if (
      !Array.isArray(scheduledQuestRunData) ||
      scheduledQuestRunData.length === 0
    ) {
      return questRunData;
    }
    return [...scheduledQuestRunData, ...questRunData];
  }, [questRunData, scheduledQuestRunData]);

  const prevSearchTextRef = useRef(searchText);
  const prevQuestRunDataRef = useRef(combinedQuestRunData);
  const prevFilteredRunDataRef = useRef<typeof combinedQuestRunData | null>(
    null
  );
  const filteredRunData = useMemo(() => {
    const prevSearchText = prevSearchTextRef.current;
    prevSearchTextRef.current = searchText;
    const prevQuestRunData = prevQuestRunDataRef.current;
    prevQuestRunDataRef.current = combinedQuestRunData;

    let runs = combinedQuestRunData;
    if (
      prevQuestRunData === combinedQuestRunData &&
      prevSearchText &&
      searchText.startsWith(prevSearchText) &&
      prevFilteredRunDataRef.current !== null
    ) {
      /**
       * If the full set of runs data hasn't changed and the last search text is a substring of the current
       * search text, then we can continue from the last set of filtered sections. This is helpful for users with
       * large amounts of Quest Runs since the search is performed purely on the front-end.
       * This will ensure the filter is built up as they type instead of fully recalculated on each character they press.
       */

      runs = prevFilteredRunDataRef.current;
    }

    if (!searchText?.trim()) {
      // Skip filter if no search text is provided
      return runs;
    }

    const searchFilter = createSearchFilter(searchText);
    return runs.filter(({ searchTerms }) => searchFilter(searchTerms));
  }, [combinedQuestRunData, searchText]);
  prevFilteredRunDataRef.current = filteredRunData;

  const isScheduledRunData = useCallback(
    (
      data: typeof combinedQuestRunData[number]
    ): data is typeof scheduledQuestRunData[number] => {
      return data.sectionKey === SCHEDULED_QUEST_STARTS_SECTION_KEY;
    },
    []
  );

  // const s2 = Date.now();

  const sectionsData = useMemo((): SectionData<string>[] => {
    if (filteredRunData.length === 0) {
      return [];
    }
    // Group runs by `sectionKey`.
    // We're able to significantly optimize over a standard _.groupBy since the `filteredRunData`
    // is pre-sorted (by `startedAt` for runs, or by `startAt` for Scheduled Quest Starts).
    // Meaning all runs with the same sectionKey sit sequentially in the list.
    const runsGroupedBySectionKey: SectionData<string>[] = [];
    let lastSectionKey = filteredRunData?.[0]?.sectionKey;
    let currentSectionData: string[] = [];
    for (let i = 0; i < filteredRunData.length; i++) {
      const run = filteredRunData[i];
      if (run.sectionKey === lastSectionKey) {
        currentSectionData.push(run.id);
      } else {
        const previousRun = filteredRunData[Math.max(0, i - 1)];
        const isScheduledQuestStart = isScheduledRunData(previousRun);
        runsGroupedBySectionKey.push({
          key: previousRun.sectionKey,
          title: isScheduledQuestStart
            ? "Scheduled Runs"
            : sectionGroupTitleFormatter.format(
                new Date(previousRun.startedAt)
              ),
          data: currentSectionData,
        });
        currentSectionData = [run.id];
      }
      lastSectionKey = run.sectionKey;
    }
    if (currentSectionData.length > 0) {
      const lastRun = filteredRunData[filteredRunData.length - 1];
      runsGroupedBySectionKey.push({
        key: lastRun.sectionKey,
        title: isScheduledRunData(lastRun)
          ? "Scheduled Runs"
          : sectionGroupTitleFormatter.format(new Date(lastRun.startedAt)),
        data: currentSectionData,
      });
    }
    return runsGroupedBySectionKey;
  }, [filteredRunData, isScheduledRunData]);
  // const s3 = Date.now();

  // console.log(
  //   "QuestRunList Performance - Runs",
  //   questRunData.length,
  //   "Step 1",
  //   s1 - start,
  //   "Step 2",
  //   s2 - s1,
  //   "Step 3",
  //   s3 - s2
  // );

  return (
    <StyledChooseEntry {...chooseEntryProps} sectionsData={sectionsData} />
  );
});
QuestRunList.displayName = "QuestRunList";

const StyledChooseEntry = styled(ChooseEntry).attrs({
  contentContainerStyle: {
    paddingBottom: 120,
    maxWidth: 680,
    width: "100%",
  },
})``;

const ListWidthContainer = styled.View`
  width: 100%;
  max-width: 680px;
  align-self: center;
  padding-horizontal: 20px;
`;

const StyledSearchWithTabsAndFilters = styled(SearchWithTabsAndFilters)`
  margin-top: 20px;
`;

const QuestListPlaceholderTextWrapper = styled.View`
  margin-bottom: 20px;
`;

const ActionButtonBottomPanel = styled.View`
  position: absolute;
  justify-content: center;
  bottom: 0;
  height: 80px;
  width: 100%;
`;

const ActionButtonRow = styled.View`
  flex-direction: row;
  justify-content: center;
  margin-horizontal: 20px;
`;

const requestFn = (
  args: Parameters<typeof apiRequest>
): Promise<Omit<Quests.RunsDetail.ResponseBody, "data">> =>
  apiRequest<Quests.RunsDetail.ResponseBody>(...args).then((response) => {
    store.dispatch(questInstanceListLoaded(response.data));
    return {
      // Avoid caching all the run data in SWR as we only use it from the redux cache anyway.
      nextCursor: response.nextCursor,
    };
  });

type MinimumRunData = {
  id: string;
  name: string;
  status: string;
  archived: boolean;
  startedAt: string;
  completedAt: string | null;
  alertAt: string | null | undefined;
  remindAt: string | null | undefined;
  dueAt: string | null;
  allowOverdueSubmissions: boolean;
  submittedByUserId: string | null;
  questId: string;
  prototypeId: string;
  assignmentIds: string[];
  itemInstanceIds: string[];
};

function hasMinimumRunListData(
  questInstance: MinimumRunData | ReturnType<typeof selectQuestInstanceById>
): questInstance is MinimumRunData {
  return Boolean(
    questInstance &&
      questInstance.id &&
      questInstance.questId &&
      questInstance.prototypeId &&
      questInstance.name &&
      questInstance.status &&
      questInstance.archived !== undefined &&
      questInstance.startedAt &&
      (questInstance.completedAt === null || questInstance.completedAt) &&
      (questInstance.dueAt === null || questInstance.dueAt) &&
      questInstance.allowOverdueSubmissions !== undefined &&
      (questInstance.submittedByUserId === null ||
        questInstance.submittedByUserId) &&
      Array.isArray(questInstance.assignmentIds) &&
      Array.isArray(questInstance.itemInstanceIds)
  );
}

interface FilterDefinition {
  label: string;
  id: string;
  icon?: IconIdentifier;
}

type FilterGroup = {
  id: string;
  label: string;
  filters: FilterDefinition[];
  exclusivity?: "none" | "group"; // | "all"
  icon?: IconIdentifier;
};

const getFiltersForGroup = (group: FilterGroup) => {
  return group.filters.map((filter) => {
    return {
      name: filter.label,
      id: filter.id,
      type: group.id,
      icon: filter.icon ?? group.icon ?? "filter",
    } satisfies Filter;
  });
};

export type FilterIdsByGroupId = Record<string, string[]>;

const useFilters = (
  _filterGroups: FilterGroup[],
  /**
   * Object where keys are filter types and values are filter ids
   */
  _activeFilterIdsByGroup: FilterIdsByGroupId,
  setActiveFilterIdsByGroup: Dispatch<SetStateAction<FilterIdsByGroupId>>
): FilterConfig => {
  const filterGroups = useIsEqualMemo(_filterGroups);
  const activeFilterIdsByGroup = useIsEqualMemo(_activeFilterIdsByGroup);

  const allFilters = useMemo(
    () => filterGroups.flatMap(getFiltersForGroup),
    [filterGroups]
  );
  const activeFilters = useMemo(
    () =>
      Object.entries(activeFilterIdsByGroup).reduce((acc, [groupId, ids]) => {
        for (const id of ids) {
          const filter = allFilters.find(
            (filter) => filter.id === id && filter.type === groupId
          );
          if (!filter) {
            continue;
          }

          acc[id] = filter;

          if (
            filterGroups.find((group) => group.id === groupId)?.exclusivity ===
            "group"
          ) {
            // Ensure no more filters of this type can be applied.
            break;
          }
        }
        return acc;
      }, {} as Record<string, Filter>),
    [activeFilterIdsByGroup, allFilters, filterGroups]
  );

  const activeFiltersRef = useRef(activeFilters);
  activeFiltersRef.current = activeFilters;
  const setActiveFilters: FiltersProps["setActiveFilters"] = useCallback(
    (
      setStateFn: (prevState: { [filterId: string]: Filter }) => {
        [filterId: string]: Filter;
      }
    ) => {
      const newActiveFilters = setStateFn(activeFiltersRef.current);
      const newActiveFilterIdsByType = Object.entries(newActiveFilters).reduce(
        (acc, [id, filter]) => {
          acc[filter.type] ??= [];
          acc[filter.type].push(id);
          return acc;
        },
        {} as Record<string, string[]>
      );
      setActiveFilterIdsByGroup(newActiveFilterIdsByType);
    },
    [setActiveFilterIdsByGroup]
  );

  const availableFilters = useMemo(() => {
    return filterGroups.reduce((acc, group) => {
      acc[group.id] = getFiltersForGroup(group);
      return acc;
    }, {} as Record<string, Filter[]>);
  }, [filterGroups]);

  const filterTypes = useMemo(() => {
    return filterGroups.reduce((acc, group) => {
      acc[group.id] = {
        name: group.label,
        icon: group.icon ?? "filter",
        exclusivity: group.exclusivity === "group" ? "type" : "none",
      };
      return acc;
    }, {} as FilterTypes);
  }, [filterGroups]);

  return useMemo(
    () => ({
      activeFilters,
      setActiveFilters,
      availableFilters,
      filterTypes,
    }),
    [activeFilters, availableFilters, filterTypes, setActiveFilters]
  );
};

const RUN_FILTER_DEFINITIONS = [
  {
    label: "Archived",
    id: "archived",
    exclusivity: "group",
    filters: [
      {
        label: "Include Archived",
        id: "include",
      },
      {
        label: "Only Archived",
        id: "true",
      },
    ],
  },
  {
    label: "Status",
    id: "status",
    filters: [
      {
        label: "Open",
        id: "OPEN",
      },
      {
        label: "In Review",
        id: "IN_REVIEW",
      },
      {
        label: "Completed",
        id: "COMPLETED",
      },
    ],
  },
] satisfies FilterGroup[];

const selectFilteredQuestRuns = (
  state: AppState,
  questId: string,
  activeFilters: FilterIdsByGroupId
) =>
  (state.cache.questInstanceViews.sortedIdsByQuestId[questId] ?? [])
    // TODO: Consider pre-calculating expensive information needed for filtering per instance and
    //       store in the `questInstanceViews` reducer.
    //       This could save time during this step.
    .filter((questInstanceId) => {
      const questInstance =
        state.cache.questInstances.entities[questInstanceId];
      const questPrototypeId = questInstance?.prototypeId;
      if (!questPrototypeId) {
        return false;
      }
      const matchesStatusFilter =
        !activeFilters.status ||
        activeFilters.status.length === 0 ||
        activeFilters.status.includes(questInstance?.status as string);
      if (!matchesStatusFilter) {
        return false;
      }
      const isOverdueAndAnyStatusFilterIsApplied =
        activeFilters.status?.length &&
        questInstance?.status !== "COMPLETED" &&
        !questInstance.allowOverdueSubmissions &&
        questInstance.dueAt &&
        new Date(questInstance.dueAt) < new Date();
      if (isOverdueAndAnyStatusFilterIsApplied) {
        return false;
      }

      if (
        !!questInstance.parentFormInstanceId ||
        !!questInstance.parentItemPrototypeId
      ) {
        // Exclude Subquest Runs
        return false;
      }

      const matchesArchiveFilter =
        activeFilters.archived?.[0] === "include"
          ? true
          : activeFilters.archived?.[0] === "true"
          ? questInstance.archived
          : !questInstance.archived;
      if (!matchesArchiveFilter) {
        return false;
      }
      if (!hasMinimumRunListData(questInstance)) {
        return false;
      }

      if (
        !questInstance?.submittedByUserId &&
        questInstance?.assignmentIds?.length === 1 &&
        questInstance.assignmentIds[0]
      ) {
        const assigneeId =
          state.cache.assignments.entities[questInstance.assignmentIds[0]]
            ?.assigneeId;
        if (assigneeId) {
          if (state.cache.users.entities[assigneeId]?.isAnonymous) {
            return false;
          }
        }
      }

      return true;
    })
    .map((id) => state.cache.questInstanceViews.runListViewsById[id]);

const _RunListItemContainer: React.FC<{ questInstanceId: string }> = ({
  questInstanceId,
}) => {
  // TODO: Consider moving all these calculations to the `questInstanceViews` reducer to avoid recalculations.
  //  It would require more diligence ensuring the data in those views is kept up-to-date though...
  const statusPills = useAppSelector((state) => {
    const questInstance = selectQuestInstanceById(
      state,
      questInstanceId
    ) as MinimumRunData;
    const _statusPills: QuestRunListItemData["statusPills"] = [
      questInstance.archived
        ? { text: "Archived", color: colors.neutral300 }
        : undefined,
      questInstance.status !== "COMPLETED" &&
      !questInstance.allowOverdueSubmissions &&
      questInstance.dueAt &&
      new Date(questInstance.dueAt) < new Date()
        ? { text: "Overdue", color: colors.red100 }
        : undefined,
      questInstance.status === "COMPLETED"
        ? { text: "Completed", color: colors.green100 }
        : questInstance.status === "IN_REVIEW"
        ? { text: "In Review", color: colors.yellow100 }
        : questInstance.status === "OPEN"
        ? { text: "Open", color: colors.primary100 }
        : undefined,
    ].filter(isNotUndefined);

    return _statusPills;
  }, isEqual);

  const progress = useAppSelector((state) => {
    const questInstance = selectQuestInstanceById(
      state,
      questInstanceId
    ) as MinimumRunData;
    const items = questInstance
      .itemInstanceIds!.map((iiId) =>
        selectItemInstanceByComboId(state, `${questInstanceId}|${iiId}`)
      )
      .filter(isTruthy)
      .filter((item) => !item.isCompletionAction);

    return {
      completedItems: items.filter((itemInstance) => itemInstance.completed)
        .length,
      totalItems: items.length,
    };
  }, isEqual);

  const metadataFields = useAppSelector((state) => {
    const questInstance = selectQuestInstanceById(
      state,
      questInstanceId
    ) as MinimumRunData;
    const assignees = questInstance
      .assignmentIds!.map((id) => selectAssignmentById(state, id))
      .filter(isTruthy)
      .map(({ assigneeId }) => assigneeId)
      .filter(isTruthy)
      .map((assigneeId) => {
        const user = selectUserById(state, assigneeId);
        return user
          ? {
              id: user.id,
              displayName: user.displayName,
              avatarSmallUrl: user.avatarSmallUrl,
              avatarLargeUrl: user.avatarLargeUrl,
            }
          : undefined;
      })
      .filter(
        (
          user
        ): user is Overwrite<
          typeof user,
          {
            displayName: NotUndefined;
            avatarSmallUrl: NotUndefined;
            avatarLargeUrl: NotUndefined;
          }
        > =>
          !!user &&
          user.displayName !== undefined &&
          user.avatarSmallUrl !== undefined &&
          user.avatarLargeUrl !== undefined
      );

    let submittedByUser = null;
    if (questInstance.submittedByUserId) {
      const u = selectUserById(state, questInstance.submittedByUserId);
      if (u) {
        submittedByUser = {
          id: u.id,
          displayName: u.displayName,
        };
      }
    }
    return {
      dueAt: questInstance.dueAt,
      remindAt: questInstance.remindAt,
      alertAt: questInstance.alertAt,
      submittedByUser,
      completedAt: questInstance.completedAt,
      assignees,
    };
  }, isEqual);

  const templateId = useAppSelector(
    (state) =>
      (selectQuestInstanceById(state, questInstanceId) as MinimumRunData)
        .questId
  );
  const name = useAppSelector(
    (state) =>
      (selectQuestInstanceById(state, questInstanceId) as MinimumRunData).name
  );

  const onPress = useMemo(
    () =>
      createLink({
        screen: "AdminQuestRun",
        params: {
          templateId,
          questInstanceId,
        },
      }),
    [questInstanceId, templateId]
  );

  const metadata = useMemo(() => {
    const {
      alertAt,
      assignees,
      completedAt,
      dueAt,
      remindAt,
      submittedByUser,
    } = metadataFields;
    const metadata: RunListItemMetadata[] = [];
    if (assignees.length > 0) {
      const firstAssignee = assignees[0];
      metadata.push({
        icon: firstAssignee.avatarSmallUrl
          ? { url: firstAssignee.avatarSmallUrl }
          : "person",
        text: `${firstAssignee.displayName || "Public user"}${
          assignees.length > 1
            ? ` (+${assignees.length - 1} other user${
                assignees.length - 1 > 1 ? "s" : ""
              })`
            : ""
        }`,
      });
    }

    if (completedAt) {
      const submitterIsAssignee =
        assignees.length === 1 && assignees[0].id === submittedByUser?.id;

      metadata.push({
        icon: "checkmark",
        text: (
          <>
            Completed{" "}
            {!submitterIsAssignee && submittedByUser ? (
              <>
                by{" "}
                <Text size="mediumBold">
                  {submittedByUser.displayName || "Public user"}
                </Text>{" "}
              </>
            ) : (
              ""
            )}
            (
            {new Date(completedAt).toLocaleString(undefined, {
              dateStyle: "medium",
              timeStyle: "short",
            })}
            )
          </>
        ),
      });
    } else if (dueAt) {
      const dueAtDate = new Date(dueAt);
      const alertAtDate = alertAt ? new Date(alertAt) : null;
      const remindAtDate = remindAt ? new Date(remindAt) : null;
      metadata.push({
        icon: "clock",
        iconBadge: (
          <DueDateBadge
            dueAt={dueAtDate}
            alertAt={alertAtDate}
            remindAt={remindAtDate}
          />
        ),
        text: (
          <>
            Due <RelativeDateText date={dueAtDate} size={"mediumBold"} /> (
            {dueAtDate.toLocaleString(undefined, {
              dateStyle: "medium",
              timeStyle: "short",
            })}
            )
          </>
        ),
      });
    }
    return metadata;
  }, [metadataFields]);

  return (
    <QuestRunListItem
      onPress={onPress}
      metadata={metadata}
      name={name}
      statusPills={statusPills}
      progressIcon={"checkmark"}
      progressCount={progress.completedItems}
      progressTotal={progress.totalItems}
    />
  );
};
const RunListItemContainer = React.memo(_RunListItemContainer);
RunListItemContainer.displayName = "RunListItemContainer";

const sectionGroupTitleFormatter = new Intl.DateTimeFormat(undefined, {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
});
