import React, {
  type Dispatch,
  PropsWithChildren,
  type SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { QuestViewContext } from "../QuestViewContext";
import { saveQuestPrototype } from "@app/quest/edit/saveQuestPrototype";
import _ from "lodash";
import { validateQuestPrototype } from "@app/quest/edit/validateQuestPrototype";
import produce from "immer";
import { selectLoggedInUserId } from "@app/store/auth";
import { store, useAppSelector } from "@app/store";
import { ChangeSet, useChangeTracker } from "@app/quest/edit/useChangeTracker";
import {
  EditableQuestPrototypeDetails,
  selectEditableQuestPrototypeDetailsById,
  selectQuestPrototypeById,
} from "@app/store/cache/questPrototypes";
import { useEffectOnce } from "@app/util/useEffectOnce";
import isEqual from "react-fast-compare";
import { type AppId } from "@questmate/openapi-spec";
import type { ValidationError } from "@questmate/common";
import { fetchQuest } from "@app/util/client/requests/quests";
import { fetchApp } from "@app/util/client/requests/apps";
import EventEmitter from "events";
import { useStateWithRef } from "@app/components/questkit/useStateWithRef";
import { selectQuestById } from "@app/store/cache/quests";
import { selectAppAuthById } from "@app/store/cache/appAuths";

interface ManageQuestViewContextProviderProps {
  questId: string;
  questPrototypeId: string;
  advancedMode?: boolean;
  disableAutoSave?: boolean;
}

type FieldName = `${"rewards" | "items"}[${string}]`;

type UseTouchedMapResult = [
  Record<FieldName, boolean>,
  (fieldName: FieldName, touchedState: boolean) => void,
  Dispatch<SetStateAction<Record<FieldName, boolean>>>
];
const useTouchedMap = (): UseTouchedMapResult => {
  const [touchedMap, setTouchedMap] = useState<Record<FieldName, boolean>>({});
  const setFieldTouched = useCallback(
    (fieldName: FieldName, touchedState: boolean) => {
      setTouchedMap((map) => {
        return produce(map, (draft) => {
          draft[fieldName] = touchedState;
        });
      });
    },
    []
  );

  return [touchedMap, setFieldTouched, setTouchedMap];
};

export const ManageQuestViewContextProvider: React.FC<
  PropsWithChildren<ManageQuestViewContextProviderProps>
> = ({
  questId,
  questPrototypeId,
  children,
  disableAutoSave = false,
  advancedMode = false,
}) => {
  // TODO: Remove definition and usages.
  const [, setValidationContext] = useState({
    rewards: {},
  });
  const [touchedMap, setFieldTouched, setTouchedMap] = useTouchedMap();

  const questPrototypeFromServer = useAppSelector(
    (state) =>
      selectEditableQuestPrototypeDetailsById(state, questPrototypeId)!,
    isEqual
  );

  const {
    useValueWithChanges,
    getChangeSet,
    addChange,
    addChangeListener,
    removeChangeListener,
  } = useChangeTracker(questPrototypeFromServer);

  const isOwner = useAppSelector((state) => {
    return (
      selectLoggedInUserId(state) ===
      selectQuestPrototypeById(state, questPrototypeId!)?.ownerId
    );
  });

  const questValidationsManager = useMemo(
    () => new QuestValidationsManager(getChangeSet),
    [getChangeSet]
  );
  useEffect(
    () => () => questValidationsManager.destroy(),
    [questValidationsManager]
  );
  useEffect(() => {
    const listener = () => {
      void questValidationsManager.validateQuestPrototype(
        getChangeSet().valueWithChanges
      );
    };

    addChangeListener(listener);

    return () => {
      removeChangeListener(listener);
    };
  }, [
    questValidationsManager,
    getChangeSet,
    addChangeListener,
    removeChangeListener,
  ]);

  const markAllFieldsAsTouched = useCallback(() => {
    setTouchedMap((map: Record<FieldName, boolean>) => {
      const questPrototype = getChangeSet().valueWithChanges;
      const newMap = { ...map };
      questPrototype.itemIds?.forEach((id) => {
        newMap[`items[${id}]`] = true;
      });
      questPrototype.rewardIds?.forEach((id) => {
        newMap[`rewards[${id}]`] = true;
      });
      return newMap;
    });
  }, [getChangeSet, setTouchedMap]);

  useEffectOnce(() => {
    // set the initial error state
    void questValidationsManager.validateQuestPrototype(
      getChangeSet().valueWithChanges
    );

    // Set everything as touched initially to show any validation errors.
    markAllFieldsAsTouched();
  });

  const useScopedValidationErrors = useMemo(() => {
    return questValidationsManager.useScopedValidationErrors.bind(
      questValidationsManager
    );
  }, [questValidationsManager]);

  const { isSaving, save } = useSaveQuestPrototype_internal(
    questPrototypeId,
    disableAutoSave,
    getChangeSet,
    addChangeListener,
    removeChangeListener
  );

  const context = useMemo(() => {
    return {
      questPrototypeId,
      questId,
      viewContext: "MANAGE",
      isOwner,
      save,
      isSaving,
      addChange,
      useQuestPrototypeWithChanges: useValueWithChanges,
      useScopedValidationErrors,
      errorMap: {},
      touchedMap,
      setFieldTouched,
      markAllFieldsAsTouched,
      setValidationContext,
      advancedMode,
    } as const;
  }, [
    addChange,
    useValueWithChanges,
    setFieldTouched,
    markAllFieldsAsTouched,
    save,
    useScopedValidationErrors,
    // above are unchanging
    questPrototypeId,
    questId,
    touchedMap,
    isOwner,
    isSaving,
    advancedMode,
  ]);
  return (
    <QuestViewContext.Provider value={context}>
      {children}
    </QuestViewContext.Provider>
  );
};

function useSaveQuestPrototype_internal(
  questPrototypeId: string | undefined,
  disableAutoSave: boolean,
  getChangeSet: () => ChangeSet<EditableQuestPrototypeDetails>,
  addChangeListener: (listener: () => void) => void,
  removeChangeListener: (listener: () => void) => void
) {
  const inFlightSavesCounterRef = useRef<number>(0);
  const [isSaving, setIsSaving] = useState(false);

  const save = useCallback(
    async () => {
      const changeSet = getChangeSet();
      const updatedQuestPrototype = changeSet.valueWithChanges;
      if (!updatedQuestPrototype || !changeSet.hasUnsavedChanges) {
        return true;
      }

      if (inFlightSavesCounterRef.current++ === 0) {
        // only set state when its value would change
        setIsSaving(true);
      }
      const pendingChanges = changeSet.markPending();
      let saveSuccessful;
      try {
        const saveResult = await saveQuestPrototype(
          questPrototypeId!,
          changeSet
        );
        saveSuccessful = saveResult.success;
        changeSet.markSaved(pendingChanges);
      } catch (_e) {
        saveSuccessful = false;
        changeSet.markUnsaved(pendingChanges);
      }

      if (--inFlightSavesCounterRef.current === 0) {
        // only set state when its value would change
        setIsSaving(false);
      }

      return saveSuccessful;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [questPrototypeId]
  );

  const debouncedSave = useMemo(() => _.debounce(save, 500), [save]);

  useEffectOnce(() => {
    // autosave when changes are added
    const changeListener = () => {
      if (!disableAutoSave) {
        debouncedSave()?.catch((e) =>
          console.error(
            "Unexpected error occurred when saving QuestPrototype: ",
            e
          )
        );
      }
    };
    addChangeListener(changeListener);
    return () => {
      removeChangeListener(changeListener);
    };
  });

  return { isSaving, save };
}

interface Observer<T> {
  stop: () => void;
  start: () => void;
  updateValue: (value: T) => void;
}

abstract class ObservingContextFieldProvider<
  F extends string = string,
  V extends (...args: unknown[]) => Promise<unknown> = (
    ...args: unknown[]
  ) => Promise<unknown>
> extends EventEmitter {
  field: F;

  getValue = async (
    ...args: Parameters<V>
  ): Promise<Awaited<ReturnType<V>>> => {
    const key = this.serializeArgs(args);
    if (!(key in this.observers)) {
      this.observers[key] = this.createObserver(args, () =>
        this.emit("change", { field: this.field, args })
      );
    }
    this.observers[key].stop();

    const value = await this.getValueImpl(...args);

    this.observers[key].updateValue(value);
    this.observers[key].start();

    return value;
  };

  protected serializeArgs(args: unknown[]): string {
    return JSON.stringify(args);
  }

  private observers: Record<string, Observer<Awaited<ReturnType<V>>>> = {};

  clearObservers() {
    Object.values(this.observers).forEach((observer) => {
      observer.stop();
    });
    this.observers = {};
  }

  protected abstract createObserver(
    args: Parameters<V>,
    reportChange: () => void
  ): Observer<Awaited<ReturnType<V>>>;
  protected abstract getValueImpl(
    ...args: Parameters<V>
  ): Promise<Awaited<ReturnType<V>>>;
}

type AppAuthValidationInfo = { id: AppId; name: string; isLinked: boolean };
class AppInfoContextProvider extends ObservingContextFieldProvider<
  "getAppAuthInfo",
  (appId: AppId) => Promise<AppAuthValidationInfo>
> {
  field = "getAppAuthInfo" as const;
  async getValueImpl(appId: AppId) {
    let appAuth = selectAppAuthById(store.getState(), appId);
    if (!appAuth) {
      try {
        appAuth = await fetchApp(appId).execute();
      } catch (e) {
        console.warn("Failed to fetch App Auth with ID", appId, e);
      }
    }
    if (appAuth?.id) {
      return Promise.resolve({
        id: appAuth.id,
        name: appAuth.name,
        isLinked: appAuth.linked,
      });
    } else {
      return Promise.resolve({
        id: appId,
        name: appId,
        isLinked: false,
      });
    }
  }

  createObserver(
    args: Parameters<typeof this.getValueImpl>,
    reportChange: () => void
  ) {
    const appId = args[0];
    return {
      _lastLinkedState: undefined,
      _unsubscribeFn: undefined,
      start() {
        this._unsubscribeFn?.();
        this._unsubscribeFn = store.subscribe(() => {
          const appLinked =
            selectAppAuthById(store.getState(), appId)?.linked ?? false;
          if (appLinked !== this._lastLinkedState) {
            this._lastLinkedState = appLinked;
            reportChange();
          }
        });
      },
      stop() {
        this._unsubscribeFn?.();
      },
      updateValue(value: AppAuthValidationInfo) {
        this._lastLinkedState = value?.isLinked ?? false;
      },
    };
  }
}
class QuestExistsContextProvider extends ObservingContextFieldProvider<
  "checkQuestExists",
  (questId: string) => Promise<boolean>
> {
  field = "checkQuestExists" as const;

  async getValueImpl(questId: string) {
    if (selectQuestById(store.getState(), questId)?.id) {
      return Promise.resolve(true);
    }
    try {
      const quest = await fetchQuest(questId).execute();
      return Boolean(quest?.id);
    } catch (e) {
      console.warn("Failed to fetch Quest with ID", questId, e);
      return Promise.resolve(false);
    }
  }

  createObserver(
    args: Parameters<typeof this.getValueImpl>,
    reportChange: () => void
  ) {
    const questId = args[0];
    return {
      _lastValue: undefined,
      _unsubscribeFn: undefined,
      start() {
        this._unsubscribeFn?.();
        this._unsubscribeFn = store.subscribe(() => {
          const questExists = Boolean(
            selectQuestById(store.getState(), questId)?.id
          );
          if (questExists !== this._lastValue) {
            reportChange();
          }
        });
      },
      stop() {
        this._unsubscribeFn?.();
      },
      updateValue(value: boolean) {
        this._lastValue = value;
      },
    };
  }
}

type CombinedContextProviders<T extends ObservingContextFieldProvider> = {
  [K in T["field"]]: T extends { field: K; getValue: infer V } ? V : never;
};

function combineContextProviders<T extends ObservingContextFieldProvider>(
  providers: T[]
): CombinedContextProviders<T> {
  return providers.reduce((acc, provider) => {
    acc[provider.field] = provider.getValue;
    return acc;
  }, {} as Record<string, unknown>) as CombinedContextProviders<T>;
}

class ValidationContextProvider<
  T extends ObservingContextFieldProvider
> extends EventEmitter {
  private listener = (eventData: unknown) => {
    this.emit("change", eventData);
  };

  constructor(private readonly contextFieldProviders: T[]) {
    super();
  }

  startObservingChangesInContext() {
    this.contextFieldProviders.forEach((contextFieldProvider) => {
      contextFieldProvider.on("change", this.listener);
    });
  }
  stopObservingChangesInContext() {
    this.contextFieldProviders.forEach((contextFieldProvider) => {
      contextFieldProvider.clearObservers();
      contextFieldProvider.off("change", this.listener);
    });
  }

  getContext(): CombinedContextProviders<T> {
    return combineContextProviders(this.contextFieldProviders);
  }
}

class QuestValidationsManager extends EventEmitter {
  private errors: readonly ValidationError[] = [];
  private readonly validationContextProvider;

  constructor(getChangeSet: () => ChangeSet<EditableQuestPrototypeDetails>) {
    super();
    this.validationContextProvider = new ValidationContextProvider([
      new QuestExistsContextProvider(),
      new AppInfoContextProvider(),
    ]);
    this.validationContextProvider.startObservingChangesInContext();
    this.validationContextProvider.on("change", () => {
      // TODO: Debounce this...
      // TODO: Possibly validate only the items that are effected by the change in context.
      void this.validateQuestPrototype(getChangeSet().valueWithChanges);
    });
  }

  destroy() {
    this.removeAllListeners();
    this.validationContextProvider.stopObservingChangesInContext();
    this.validationContextProvider.removeAllListeners();
  }

  async validateQuestPrototype(
    currentQuestPrototype: EditableQuestPrototypeDetails
  ) {
    this.validationContextProvider.stopObservingChangesInContext();
    const validationResult = await validateQuestPrototype(
      currentQuestPrototype,
      this.validationContextProvider.getContext()
    );
    this.validationContextProvider.startObservingChangesInContext();

    this.setValidationErrors(
      validationResult.valid ? [] : validationResult.errors
    );

    return validationResult;
  }

  setValidationErrors(updatedErrors: readonly ValidationError[]) {
    if (!isEqual(this.errors, updatedErrors)) {
      this.errors = Object.freeze(updatedErrors);
      this.emit("change", this.errors);
    }
  }

  useScopedValidationErrors(pathScope: string[]): ValidationError[] {
    const pathScopeRef = useRef(pathScope);
    pathScopeRef.current = pathScope;

    const getScopedErrors = useCallback(() => {
      const path = pathScopeRef.current;

      return this.errors
        .filter((error) => {
          for (let i = 0; i < path.length; i++) {
            if (error.path[i] !== path[i]) {
              return false;
            }
          }
          return true;
        })
        .map((error) => {
          return {
            ...error,
            // Trim start of path that matches scope to simplify consumer use of path.
            path: error.path.slice(path.length),
          };
        });
    }, []);

    const [errors, setErrors, errorsRef] = useStateWithRef(getScopedErrors);

    useEffectOnce(() => {
      const listener = () => {
        const updatedErrors = getScopedErrors();
        if (!isEqual(errorsRef.current, updatedErrors)) {
          setErrors(updatedErrors);
        }
      };
      this.on("change", listener);

      return () => {
        this.off("change", listener);
      };
    });

    return errors;
  }
}
