import React, { useEffect, useMemo } from "react";
import { ItemBaseProps, ItemContainerWrapper } from "../itemContainer";
import { ItemInstanceData } from "@app/types/itemRenderData";
import styled from "styled-components/native";
import { useQuestPrototype } from "@app/quest/useQuestPrototype";
import { fetchQuestInstance } from "@app/util/client/requests/quests";
import { AppState, useAppSelector } from "@app/store";
import { selectQuestPrototypeById } from "@app/store/cache/questPrototypes";
import { selectItemPrototypeById } from "@app/store/cache/itemPrototypes";
import { createLink } from "@app/util/link.utils";
import {
  QuestRunListItem,
  RunListItemMetadata,
} from "@app/quest/run/QuestRunListItem";
import Icon from "@app/components/icon";
import { InlineErrorWithRetry } from "@app/components/item/components/custom/InlineErrorWithRetry";
import { isNotUndefined } from "@questmate/common";
import { OnPress } from "@app/components/questkit/BasePressable";
import { useQuestViewContext } from "@app/quest/QuestViewContext";
import { selectQuestInstanceById } from "@app/store/cache/questInstances";
import { RelativeDateText } from "@app/components/questkit/RelativeDateText";
import { DueDateBadge } from "@app/quest/run/DueDateBadge";
import isEqual from "react-fast-compare";
import { useRequest } from "@app/util/client/requests";
import { selectItemInstanceByComboId } from "@app/store/cache/itemInstances";
import { QKActivityIndicator } from "@app/components/questkit/activityIndicator";

export const SubquestItem: React.FC<ItemBaseProps> = (props) => {
  const item = props.item as ItemInstanceData;
  const { editMode, setItemAction } = props;

  const { questId } = useQuestViewContext();
  const subquestInstancesOnItemInstance = item.subquestInstances;
  const subquestIdInEditMode = item.prototype.subquestId;
  const firstSubquestInstanceIdInFilloutMode =
    subquestInstancesOnItemInstance?.[0]?.id;

  const action = useMemo(() => {
    if (editMode && subquestIdInEditMode && questId) {
      return createLink({
        screen: "Quest",
        params: {
          questId: questId,
          screen: "QuestEdit",
          params: {
            questPrototypeId: subquestIdInEditMode,
          },
        },
        type: "PUSH",
      });
    } else if (firstSubquestInstanceIdInFilloutMode) {
      return createLink({
        screen: "AdminQuestRun",
        params: {
          templateId: questId!,
          questInstanceId: firstSubquestInstanceIdInFilloutMode,
        },
        type: "PUSH",
      });
    }
    return undefined;
  }, [
    editMode,
    subquestIdInEditMode,
    firstSubquestInstanceIdInFilloutMode,
    questId,
  ]);
  useEffect(() => setItemAction?.(action), [action, setItemAction]);

  return (
    <ItemContainerWrapper
      {...props}
      inlineNode={<Icon icon={"chevron-right"} />}
      blockNode={
        editMode ? (
          <SubquestEditCard
            subquestPrototypeId={subquestIdInEditMode}
            onPress={action}
          />
        ) : (
          <>
            {item.subquestInstances?.map((instance) => (
              <SubquestRunCard
                key={instance.id}
                questId={questId!}
                subquestInstanceId={instance.id}
              />
            ))}
          </>
        )
      }
    />
  );
};

interface SubquestEditCardProps {
  subquestPrototypeId?: string;
  onPress: OnPress;
}

const SubquestEditCard: React.FC<SubquestEditCardProps> = ({
  subquestPrototypeId,
  onPress,
}) => {
  const {
    isLoading: isLoadingSubquestPrototype,
    refresh: refreshSubquestPrototype,
  } = useQuestPrototype(subquestPrototypeId);

  const subquestPrototypeData = useAppSelector(
    (state) =>
      subquestPrototypeId
        ? selectSubquestItemEditData(state, subquestPrototypeId)
        : undefined,
    isEqual
  );

  return subquestPrototypeData ? (
    <EditQuestRunListItem
      metadata={[]}
      onPress={onPress}
      progressTotal={subquestPrototypeData.totalItems}
      progressCount={0}
    />
  ) : isLoadingSubquestPrototype || !subquestPrototypeId ? (
    <QKActivityIndicator />
  ) : (
    <InlineErrorWithRetry
      message={"Failed to load Subquest."}
      isLoading={isLoadingSubquestPrototype}
      onRetry={refreshSubquestPrototype}
    />
  );
};

interface SubquestRunCardProps {
  questId: string;
  subquestInstanceId: string;
}

const SubquestRunCard: React.FC<SubquestRunCardProps> = ({
  questId,
  subquestInstanceId,
}) => {
  const {
    error: formInstanceError,
    isValidating: isLoadingFormInstance,
    refresh: refreshFormInstance,
  } = useRequest(fetchQuestInstance(subquestInstanceId || undefined));

  const progressProps = useAppSelector((state) => {
    const questInstance = selectQuestInstanceById(state, subquestInstanceId);
    if (!questInstance) {
      return;
    }
    const allItemInstances = questInstance.itemInstanceIds
      ?.map((itemPrototypeId) => {
        return selectItemInstanceByComboId(
          state,
          `${subquestInstanceId}|${itemPrototypeId}`
        );
      })
      .filter(isNotUndefined);

    if (allItemInstances) {
      const itemInstances = allItemInstances.filter(
        (itemInstance) => !itemInstance.isCompletionAction
      );
      return {
        progressCount: itemInstances.filter(({ completed }) => completed)
          .length,
        progressTotal: itemInstances.length,
      };
    }
  });

  const metadata = useAppSelector((state) =>
    selectSubquestRunMetadata(state, subquestInstanceId)
  );

  const onPress = useMemo(() => {
    return createLink({
      screen: "AdminQuestRun",
      params: {
        templateId: questId,
        questInstanceId: subquestInstanceId,
      },
      type: "PUSH",
    });
  }, [questId, subquestInstanceId]);

  return progressProps && metadata ? (
    <QuestRunListItem
      {...progressProps}
      metadata={metadata}
      onPress={onPress}
    />
  ) : formInstanceError ? (
    <InlineErrorWithRetry
      message={"Failed to load Subquest Run."}
      isLoading={isLoadingFormInstance}
      onRetry={refreshFormInstance}
    />
  ) : null;
};

const selectSubquestRunMetadata = (
  state: AppState,
  subquestRunFormInstanceId: string | undefined
) => {
  if (!subquestRunFormInstanceId) {
    return undefined;
  }

  const metadata: RunListItemMetadata[] = [];
  const questInstance = selectQuestInstanceById(
    state,
    subquestRunFormInstanceId
  );
  if (
    questInstance &&
    questInstance.completedAt !== undefined &&
    questInstance.dueAt !== undefined
  ) {
    if (questInstance.completedAt) {
      metadata.push({
        icon: "checkmark",
        text: (
          <>
            Completed (
            {new Date(questInstance.completedAt).toLocaleString(undefined, {
              dateStyle: "medium",
              timeStyle: "short",
            })}
            )
          </>
        ),
      });
    } else if (questInstance.dueAt) {
      const dueAt = new Date(questInstance.dueAt);
      metadata.push({
        icon: "clock",
        iconBadge: (
          <DueDateBadge
            dueAt={dueAt}
            alertAt={
              questInstance.alertAt ? new Date(questInstance.alertAt) : null
            }
            remindAt={
              questInstance.remindAt ? new Date(questInstance.remindAt) : null
            }
          />
        ),
        text: (
          <>
            Due <RelativeDateText date={dueAt} size={"mediumBold"} /> (
            {dueAt.toLocaleString(undefined, {
              dateStyle: "medium",
              timeStyle: "short",
            })}
            )
          </>
        ),
      });
    }
  }

  return metadata;
};

const selectSubquestItemEditData = (
  state: AppState,
  subquestPrototypeId: string | undefined
) => {
  if (!subquestPrototypeId) {
    return undefined;
  }
  const subquestPrototype = selectQuestPrototypeById(
    state,
    subquestPrototypeId
  );
  if (!subquestPrototype || !subquestPrototype.itemIds) {
    return undefined;
  }

  return {
    totalItems: subquestPrototype.itemIds
      .map((itemId) => {
        const itemPrototype = selectItemPrototypeById(state, itemId);
        if (!itemPrototype) {
          return undefined;
        }
        return itemPrototype;
      })
      .filter(isNotUndefined)
      .filter((item) => !item.isCompletionAction).length,
  };
};

const EditQuestRunListItem = styled(QuestRunListItem)`
  background-color: ${({ theme }) => theme.cardInCard};
`;
