import React, { useCallback, useMemo, useRef, useState } from "react";
import Item from "@app/components/item";
import { Boundary } from "@app/components/screen/boundary";
import { FadeInView } from "@app/components/animated/fadeInView";
import CompletionAction from "@app/components/completionAction";
import styled from "styled-components/native";
import Button from "@app/components/questkit/button";
import type { ItemRenderData } from "@app/types/itemRenderData";
import type { CompletionActionRenderData } from "@app/types/completionActionRenderData";
import {
  OldQuestSectionHeader,
  QuestSectionHeader,
  QuestSectionHeaderDescriptionText,
} from "../questSectionHeader";
import {
  DragDropList,
  type DraggableItemRenderer,
} from "@app/components/dragdrop";
import { View } from "react-native";
import { ContentSlider } from "@app/components/questkit/ContentSlider";
import { validateItem } from "@questmate/common";
import { Text } from "@app/components/questkit";
import Icon, { IconIdentifier } from "@app/components/icon";
import { Analytics } from "@app/analytics";
import { useFocusController } from "@app/util/focus";
import { ItemType, QuestInstanceDetail } from "@questmate/openapi-spec";
import { DragDropListControllerProvider } from "@app/components/dragdrop/DragDropListControllerProvider";
import { QKLoadingIndicator } from "@app/components/loadingIndicator/QKLoadingIndicator";
import { QuestStartTrigger } from "@app/quest/start/QuestStartTrigger";
import { AddEntryCard } from "@app/quest/edit/AddEntryCard";
import { QuestStartTriggerEditData } from "@app/store/cache/questPrototypes";
import isEqual from "react-fast-compare";
import { useKioskInactivityTimeout } from "@app/util/useKioskInactivityTimeout";
import { OnItemChangeHandler } from "@app/util/client/hooks/useQuestInstance";
import { sentry } from "@app/util/sentry";
import { QuestPlaylistProvider } from "@app/audio/PlaylistContext";
import { IntroText } from "@app/components/screen/quest/common/IntroText";

type ContentSliderHandle = React.ElementRef<typeof ContentSlider>;

export enum EditLevel {
  Readonly,
  Interactive,
  Editable,
}

export enum ReviewButtonBehavior {
  Hidden,
  RequiresReview,
}

export enum StatusMessageBehavior {
  Hidden,
  AwaitingSubmission,
  AwaitingReview,
  AwaitingOthersReview,
}

export enum SubmitButtonBehaviour {
  Hidden,
  Disabled,
  Editable,
  Submittable,
}

interface ItemDataProps {
  items: ItemRenderData[];
  onItemChange?: OnItemChangeHandler;
  onItemAdded?: (position: number) => void;
  onItemDelete?: (prototypeId: string) => void;
  onItemReorder?: (oldPosition: number, newPosition: number) => void;
  onItemValidate?: (itemId: string) => void;
  onAddItemPressed?: () => void;
  addItemCardIcons?: IconIdentifier[];
}

interface CompletionActionDataProps {
  completionActions: CompletionActionRenderData[];
  onCompletionActionChange?: (
    completionAction: CompletionActionRenderData
  ) => void;
  onCompletionActionAdded?: (index: number) => void;
  onCompletionActionDelete?: (prototypeId: string) => void;
  onCompletionActionReorder?: (oldIndex: number, newIndex: number) => void;
  onCompletionActionTouched?: (
    completionAction: CompletionActionRenderData
  ) => void;
  onCompletionActionValidationContextChange?: (
    completionAction: CompletionActionRenderData,
    validationContext: Record<string, unknown>
  ) => void;
  onAddCompletionActionPressed?: () => void;
  addCompletionActionCardIcons?: IconIdentifier[];
}

interface StartTriggerProps {
  startTriggers?: QuestStartTriggerEditData[];
  openStartQuestModal?: () => void;
}

export interface QuestViewProps
  extends StartTriggerProps,
    ItemDataProps,
    CompletionActionDataProps {
  introText?: string;
  onIntroTextChange?: (introText: string) => void;
  onSubmit?: () => Promise<QuestInstanceDetail> | undefined;
  onShowItemOptions?: (index: number) => void;
  onSuccess?: (
    updatedQuestInstance: QuestInstanceDetail
  ) => void | (() => void);
  loading: boolean;
  completed: boolean;
  submitButtonTitle?: string;
  itemsEditLevel: EditLevel;
  completionActionsEditLevel: EditLevel;
  reviewButtonBehavior: ReviewButtonBehavior;
  statusMessageBehavior: StatusMessageBehavior;
  onReviewStart?: () => void;
  submitButtonBehaviour: SubmitButtonBehaviour;
  canEditIntroText: boolean;
  /**
   * All defaults are 'HIDDEN'
   */
  sectionHeaders?: {
    startTriggers?: "HIDDEN" | "DETAILED";
    items?: "HIDDEN" | "DETAILED";
    completionActions?: "HIDDEN" | "DETAILED" | "MINIMAL";
  };
  renderKey?: string;
  slideMode?: boolean;
  restartButton?: React.ReactNode;
}

const _QuestView: React.FC<QuestViewProps> = ({
  startTriggers,
  openStartQuestModal,

  introText,
  onIntroTextChange,
  canEditIntroText,

  items,
  onItemChange: _onItemChange,
  onItemAdded,
  onItemDelete,
  onItemReorder,
  onItemValidate,
  itemsEditLevel,
  onAddItemPressed,
  addItemCardIcons,

  completionActions,
  onCompletionActionChange,
  onCompletionActionDelete,
  onCompletionActionReorder,
  onCompletionActionValidationContextChange,
  onCompletionActionTouched,
  completionActionsEditLevel,
  onAddCompletionActionPressed,
  addCompletionActionCardIcons,

  onSubmit,
  submitButtonTitle,
  submitButtonBehaviour,
  restartButton,
  onShowItemOptions,

  onSuccess,
  loading,
  completed,

  sectionHeaders,

  reviewButtonBehavior,
  onReviewStart,
  statusMessageBehavior,

  renderKey,

  slideMode,
}) => {
  const introTextAvailable = Boolean(introText);

  const showIntroText = canEditIntroText || introTextAvailable;
  const fadeInDelay = introTextAvailable ? 650 : 250;
  const success = completed;
  const getItemId = useCallback(
    (item: ItemRenderData) => item.prototype.id,
    []
  );
  const onItemDragEnd = useCallback(
    (oldIndex: number, newIndex: number) => {
      onItemReorder?.(oldIndex, newIndex);
    },
    [onItemReorder]
  );

  const getCompletionActionId = useCallback(
    (completionAction: CompletionActionRenderData) =>
      completionAction.prototype.id,
    []
  );
  const onCompletionActionDragEnd = useCallback(
    (oldIndex: number, newIndex: number) => {
      onCompletionActionReorder?.(oldIndex, newIndex);
    },
    [onCompletionActionReorder]
  );
  const visibleCompletionActions = useMemo(
    () =>
      completionActions.filter((completionAction) => {
        const isSlackReward =
          completionAction.prototype.type === "POST_SLACK_MESSAGE";
        const isEditMode = completionActionsEditLevel === EditLevel.Editable;
        return isEditMode || !isSlackReward;
      }),
    [completionActions, completionActionsEditLevel]
  );
  const completionActionsAvailable = Boolean(
    completed && visibleCompletionActions.length > 0
  );
  const renderDraggableCompletionAction = useCallback<
    DraggableItemRenderer<CompletionActionRenderData>
  >(
    ({ item, index, dragHandlers, isBeingDragged, isPlaceholder }) => {
      if (isPlaceholder) {
        return <StyledItemPlaceholder />;
      }
      return (
        <ItemRowWrapper>
          <CompletionAction
            item={item}
            position={index}
            onCompletionActionChange={(item) =>
              onCompletionActionChange?.(item)
            }
            onCompletionActionDelete={() =>
              onCompletionActionDelete?.(item.prototype.id)
            }
            onCompletionActionValidationContextChange={(
              validationContext: Record<string, unknown>
            ) =>
              onCompletionActionValidationContextChange?.(
                item,
                validationContext
              )
            }
            onCompletionActionTouched={() => onCompletionActionTouched?.(item)}
            editMode={completionActionsEditLevel === EditLevel.Editable}
            readOnly={completionActionsEditLevel === EditLevel.Readonly}
            isDragged={isBeingDragged}
            dragHandlers={dragHandlers}
            style={
              completionActionsEditLevel === EditLevel.Readonly
                ? { opacity: 0.4 }
                : undefined
            }
          />
        </ItemRowWrapper>
      );
    },
    [
      completionActionsEditLevel,
      onCompletionActionChange,
      onCompletionActionDelete,
      onCompletionActionTouched,
      onCompletionActionValidationContextChange,
    ]
  );
  const kioskInactivityTimeoutManager = useKioskInactivityTimeout();
  const itemsRef = useRef(items);
  itemsRef.current = items;
  const onItemChange = useCallback(
    (...args: Parameters<Exclude<typeof _onItemChange, undefined>>) => {
      const [updatedItem, options] = args;
      if (!options?.skipActivityReport) {
        if (__DEV__) {
          // Skip check in prod to avoid overhead.
          // Warn if calls to `onItemChange` are happening without any changes in the data and we are reporting activity.
          const prevItem = itemsRef.current.find(
            (item) => item?.prototype?.id === updatedItem?.prototype?.id
          );
          if (
            isEqual(prevItem?.data, updatedItem.data) &&
            isEqual(prevItem?.prototype, updatedItem.prototype)
          ) {
            const msg =
              "Warning: Reporting activity when Item has not actually changed!";
            const sentryEventId = sentry.captureMessage(msg, {
              contexts: { Details: { prevItem, updatedItem } },
            });
            console.warn(msg, "Sentry Event ID", sentryEventId);
          }
        }

        kioskInactivityTimeoutManager.reportActivityOccurred();
      }

      _onItemChange?.(...args);
    },
    [kioskInactivityTimeoutManager, _onItemChange]
  );

  const renderDraggableItem = useCallback<
    DraggableItemRenderer<ItemRenderData>
  >(
    ({ item, index, dragHandlers, isBeingDragged, isPlaceholder }) => {
      if (isPlaceholder) {
        return <StyledItemPlaceholder />;
      }

      return (
        <ItemRowWrapper>
          <Item
            item={item}
            position={index}
            onItemChange={onItemChange}
            onItemAppend={() => onItemAdded && onItemAdded(index + 1)}
            onItemDelete={() => onItemDelete && onItemDelete(item.prototype.id)}
            onItemValidate={onItemValidate}
            onShowItemOptions={() =>
              onShowItemOptions && onShowItemOptions(index)
            }
            editMode={itemsEditLevel === EditLevel.Editable}
            readOnly={itemsEditLevel === EditLevel.Readonly}
            isDragged={isBeingDragged}
            dragHandlers={dragHandlers}
          />
        </ItemRowWrapper>
      );
    },
    [
      itemsEditLevel,
      onItemAdded,
      onItemChange,
      onItemDelete,
      onItemValidate,
      onShowItemOptions,
    ]
  );

  const [isChangingQuestType, setIsChangingQuestType] = useState(false);

  // Slider specific
  const contentSliderRef = useRef<ContentSliderHandle>(null);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const focusController = useFocusController();
  const slides = useMemo(() => {
    return [
      ...(introTextAvailable
        ? [
            {
              config: {
                hideNavigation: true,
              },
            },
          ]
        : []),
      ...items.map((item, index) => ({
        isItem: true,
        itemIndex: index,
        isLastItem: items.length - 1 === index,
        config: {
          title: `${(index + 1).toString()} / ${items.length}`,
          usesKeyboard: (
            [
              "Text",
              "Email",
              "MultiLineText",
              "UserPicker",
              "Amount",
            ] as ItemType[]
          ).includes(item.prototype.type),
        },
      })),
      ...(completionActionsAvailable
        ? [
            {
              isCompletionAction: true,
              config: {
                hideNavigation: true,
              },
            },
          ]
        : []),
    ] as {
      isItem?: boolean;
      itemIndex?: number;
      isLastItem?: boolean;
      isCompletionAction?: boolean;
      config: {
        hideNavigation?: boolean;
        title?: string;
      };
    }[];
  }, [introTextAvailable, items, completionActionsAvailable]);

  const onSuccessHandler = (updatedFormInstance: QuestInstanceDetail) => {
    const onSuccessResult = onSuccess?.(updatedFormInstance);
    const safariScrollAnimationInterruptFix_delayMs = 100;
    setTimeout(() => {
      if (contentSliderRef.current) {
        contentSliderRef.current.navigateToNextSlide();
      }
    }, safariScrollAnimationInterruptFix_delayMs);
    return onSuccessResult;
  };

  const maxSlideIndex = useMemo(() => {
    let lastValidItemIndex = 0;
    for (; lastValidItemIndex < items.length; lastValidItemIndex++) {
      const item = items[lastValidItemIndex];
      if (
        !validateItem({
          required: item.prototype.required,
          type: item.prototype.type,
          data: item.data,
        }).valid
      ) {
        break;
      }
    }

    return lastValidItemIndex === items.length
      ? undefined
      : lastValidItemIndex + (showIntroText ? 1 : 0);
  }, [items, showIntroText]);

  if (slideMode) {
    return (
      <QuestPlaylistProvider>
        {items && (
          <View
            style={{ flex: 1 }}
            testID={`quest-${canEditIntroText ? "edit" : "fill"}-view`}
          >
            <ContentSlider
              ref={contentSliderRef}
              maxSlideIndex={maxSlideIndex}
              onCurrentSlideIndexChange={(newSlideIndex: number) => {
                if (newSlideIndex !== 0) {
                  kioskInactivityTimeoutManager.reportActivityOccurred();
                }
                if (newSlideIndex > currentSlideIndex) {
                  Analytics.trackEvent("Navigate to Next Slide", {
                    newSlideIndex,
                  });
                } else if (newSlideIndex < currentSlideIndex) {
                  Analytics.trackEvent("Navigate to Previous Slide", {
                    newSlideIndex,
                  });
                }
                setCurrentSlideIndex(newSlideIndex);
              }}
              onFirstViewOfSlide={(slideIndex) => {
                if (slides[slideIndex]?.isItem) {
                  const currentItemId =
                    items[slides[slideIndex].itemIndex!].prototype.id;
                  focusController.focus(currentItemId);
                }
              }}
              indexConfig={slides.map((item) => item.config)}
              containerStyle={{ padding: 20 }}
              validateBeforeNext={(currentSlideIndex: number) => {
                if (slides[currentSlideIndex].isItem) {
                  const currentItem =
                    items[slides[currentSlideIndex].itemIndex!];
                  onItemValidate?.(currentItem.prototype.id);
                  const validateResult = validateItem({
                    data: currentItem.data,
                    type: currentItem.prototype.type,
                    required: currentItem.prototype.required,
                  });
                  if (!validateResult.valid) {
                    return false;
                  }
                }
                return true;
              }}
            >
              {showIntroText ? (
                <IntroText
                  onIntroTextChange={onIntroTextChange}
                  text={introText}
                  editable={canEditIntroText}
                />
              ) : null}
              {items.map((item, index) => {
                return (
                  <Boundary key={item.prototype.id}>
                    <Item
                      slideMode={true}
                      key={item.prototype.id}
                      item={item}
                      position={index}
                      onItemChange={onItemChange}
                      onItemAppend={() => onItemAdded && onItemAdded(index + 1)}
                      onItemDelete={() =>
                        onItemDelete && onItemDelete(item.prototype.id)
                      }
                      onItemValidate={() =>
                        onItemValidate && onItemValidate(item.prototype.id)
                      }
                      onComplete={() => {
                        contentSliderRef.current!.navigateToNextSlide();
                      }}
                      onShowItemOptions={() =>
                        onShowItemOptions && onShowItemOptions(index)
                      }
                      // todo: rename editMode, possibly to isConfigurable to differentiate between being on the edit
                      //  screen and in preview mode from being on the quest instance screen. Custom items cannot show
                      //  a preview without a quest instance id unless they know they are on the edit screen.
                      editMode={itemsEditLevel === EditLevel.Editable}
                      readOnly={itemsEditLevel === EditLevel.Readonly}
                    />
                  </Boundary>
                );
              })}
              {completionActionsAvailable && (
                <>
                  <DragDropListControllerProvider>
                    <DragDropList
                      items={visibleCompletionActions}
                      getId={getCompletionActionId}
                      onDragEnd={onCompletionActionDragEnd}
                      renderItem={renderDraggableCompletionAction}
                    />
                  </DragDropListControllerProvider>
                  <QKLoadingIndicator
                    name={"allCompletionActions"}
                    loading={false}
                  />
                </>
              )}
            </ContentSlider>
            <View
              style={{ position: "absolute", bottom: 0, alignSelf: "center" }}
            >
              {slides.length <= currentSlideIndex ? null : slides[
                  currentSlideIndex
                ].isLastItem || slides[currentSlideIndex].isCompletionAction ? (
                <>
                  {submitButtonBehaviour !== SubmitButtonBehaviour.Hidden && (
                    <StyledSubmitButton
                      // this component setup should be de-duplicated between slide and not slide mode,
                      // but broke transition/animation when we tried
                      success={
                        success ||
                        statusMessageBehavior ===
                          StatusMessageBehavior.AwaitingReview ||
                        statusMessageBehavior ===
                          StatusMessageBehavior.AwaitingOthersReview
                      }
                      loading={loading}
                      testID={"submit-button"}
                      buttonType="action"
                      title={submitButtonTitle || "Submit"}
                      disabled={
                        submitButtonBehaviour === SubmitButtonBehaviour.Disabled
                      }
                      onPress={() => {
                        return onSubmit && onSubmit();
                      }}
                      onSuccess={onSuccessHandler}
                      // Set the key to `renderKey` if available to avoid restarting button animations
                      // when switching between Quest Instances in the AdminQuestRunScreen
                      key={renderKey || undefined}
                      style={{ minWidth: 220 }}
                    />
                  )}
                  {restartButton ? (
                    <>
                      <Spacer />
                      {restartButton}
                      <Spacer />
                    </>
                  ) : null}
                  {reviewButtonBehavior ===
                    ReviewButtonBehavior.RequiresReview && (
                    <>
                      <StyledSubmitButton
                        title={"Review"}
                        onPress={onReviewStart}
                      />
                    </>
                  )}
                  {statusMessageBehavior !== StatusMessageBehavior.Hidden && (
                    <>
                      <Spacer />
                      <QuestInstanceStatusMessage
                        behavior={statusMessageBehavior}
                      />
                      <Spacer />
                    </>
                  )}
                </>
              ) : (
                <StyledSubmitButton
                  title={"Continue"}
                  success={success}
                  loading={loading}
                  onPress={() => {
                    contentSliderRef.current!.navigateToNextSlide();
                  }}
                  style={{ width: 220 }}
                />
              )}
            </View>
          </View>
        )}
      </QuestPlaylistProvider>
    );
  }

  const showStartTriggers =
    Array.isArray(startTriggers) && startTriggers.length > 0;
  return (
    <QuestViewContainer
      testID={`quest-${canEditIntroText ? "edit" : "fill"}-view`}
    >
      <QuestPlaylistProvider>
        <Boundary>
          {showStartTriggers ? (
            <>
              {sectionHeaders?.startTriggers === "DETAILED" &&
              !isChangingQuestType ? (
                <>
                  <QuestSectionHeader
                    text="1. Start Settings"
                    description={
                      <QuestSectionHeaderDescriptionText size="medium">
                        Start things{" "}
                        <QuestSectionHeaderDescriptionText size="mediumBold">
                          manually
                        </QuestSectionHeaderDescriptionText>
                        , on a{" "}
                        <QuestSectionHeaderDescriptionText size="mediumBold">
                          schedule
                        </QuestSectionHeaderDescriptionText>
                        , via a{" "}
                        <QuestSectionHeaderDescriptionText size="mediumBold">
                          share link
                        </QuestSectionHeaderDescriptionText>{" "}
                        or by{" "}
                        <QuestSectionHeaderDescriptionText size="mediumBold">
                          another app
                        </QuestSectionHeaderDescriptionText>
                        .
                      </QuestSectionHeaderDescriptionText>
                    }
                    style={{ marginTop: 64 }}
                  />
                </>
              ) : null}
              <QuestStartTrigger
                startTrigger={startTriggers[0]}
                isChangingQuestType={isChangingQuestType}
                setIsChangingQuestType={setIsChangingQuestType}
                openStartQuestModal={openStartQuestModal}
                readOnly={itemsEditLevel === EditLevel.Readonly}
              />
            </>
          ) : null}
          {isChangingQuestType ? null : (
            <>
              {sectionHeaders?.items === "DETAILED" ? (
                <>
                  <QuestSectionDivider />
                  <QuestSectionHeader
                    text={`${showStartTriggers ? "2" : "1"}. Quest Items`}
                    description={
                      <QuestSectionHeaderDescriptionText size="small">
                        Add{" "}
                        <QuestSectionHeaderDescriptionText size="smallMedium">
                          tasks
                        </QuestSectionHeaderDescriptionText>
                        ,{" "}
                        <QuestSectionHeaderDescriptionText size="smallMedium">
                          form fields
                        </QuestSectionHeaderDescriptionText>
                        ,{" "}
                        <QuestSectionHeaderDescriptionText size="smallMedium">
                          remote-data dropdowns
                        </QuestSectionHeaderDescriptionText>{" "}
                        and{" "}
                        <QuestSectionHeaderDescriptionText size="smallMedium">
                          smart-device controls
                        </QuestSectionHeaderDescriptionText>{" "}
                        that you want to provide as part of your Quest.
                      </QuestSectionHeaderDescriptionText>
                    }
                    style={{ marginBottom: 0 }}
                  />
                </>
              ) : null}
              {showIntroText ? (
                <IntroText
                  onIntroTextChange={onIntroTextChange}
                  text={introText}
                  editable={canEditIntroText}
                />
              ) : (
                <View style={{ height: 80 }} />
              )}
              {items && (
                <>
                  <FadeInView
                    delay={fadeInDelay}
                    style={{ flex: 1 }}
                    testID="quest-view"
                  >
                    <DragDropListControllerProvider>
                      <DragDropList
                        items={items}
                        getId={getItemId}
                        onDragEnd={onItemDragEnd}
                        renderItem={renderDraggableItem}
                      />
                    </DragDropListControllerProvider>
                    {itemsEditLevel === EditLevel.Editable &&
                    onAddItemPressed ? (
                      <AddEntryCard
                        text="Add Item"
                        icons={addItemCardIcons ?? []}
                        onPress={onAddItemPressed}
                        testID="add-item"
                      />
                    ) : null}
                    {submitButtonBehaviour !== SubmitButtonBehaviour.Hidden && (
                      <>
                        <StyledSubmitButton
                          // this component setup should be de-duplicated between slide and not slide mode,
                          // but broke transition/animation when we tried
                          success={success}
                          loading={loading}
                          testID={"submit-button"}
                          buttonType="action"
                          title={submitButtonTitle || "Submit"}
                          disabled={
                            submitButtonBehaviour ===
                            SubmitButtonBehaviour.Disabled
                          }
                          onPress={() => onSubmit && onSubmit()}
                          onSuccess={onSuccessHandler}
                          // Set the key to `renderKey` if available to avoid restarting button animations
                          // when switching between Quest Instances in the AdminQuestRunScreen
                          key={renderKey || undefined}
                        />
                      </>
                    )}
                    {reviewButtonBehavior ===
                      ReviewButtonBehavior.RequiresReview && (
                      <>
                        <Spacer />
                        <StyledSubmitButton
                          title={"Review"}
                          onPress={onReviewStart}
                        />
                      </>
                    )}
                    {statusMessageBehavior !== StatusMessageBehavior.Hidden && (
                      <>
                        <Spacer />
                        <Spacer />
                        <QuestInstanceStatusMessage
                          behavior={statusMessageBehavior}
                        />
                      </>
                    )}
                    {sectionHeaders?.completionActions === "DETAILED" ? (
                      <>
                        <QuestSectionDivider />
                        <QuestSectionHeader
                          text={`${
                            showStartTriggers ? "3" : "2"
                          }. “On Completion” Actions`}
                          description={
                            <QuestSectionHeaderDescriptionText size="small">
                              Send a{" "}
                              <QuestSectionHeaderDescriptionText size="smallMedium">
                                Slack Message
                              </QuestSectionHeaderDescriptionText>
                              , add a row to{" "}
                              <QuestSectionHeaderDescriptionText size="smallMedium">
                                Google Sheet
                              </QuestSectionHeaderDescriptionText>
                              , attach{" "}
                              <QuestSectionHeaderDescriptionText size="smallMedium">
                                Rewards{" "}
                              </QuestSectionHeaderDescriptionText>{" "}
                              or grant{" "}
                              <QuestSectionHeaderDescriptionText size="smallMedium">
                                WiFi Access
                              </QuestSectionHeaderDescriptionText>
                              .
                            </QuestSectionHeaderDescriptionText>
                          }
                        />
                      </>
                    ) : sectionHeaders?.completionActions === "MINIMAL" ? (
                      <OldQuestSectionHeader text={"Completion Actions"} />
                    ) : null}
                    <DragDropListControllerProvider>
                      <DragDropList
                        items={visibleCompletionActions}
                        getId={getCompletionActionId}
                        onDragEnd={onCompletionActionDragEnd}
                        renderItem={renderDraggableCompletionAction}
                      />
                    </DragDropListControllerProvider>
                    {restartButton ? restartButton : null}

                    {completionActionsEditLevel === EditLevel.Editable &&
                    onAddCompletionActionPressed ? (
                      <AddEntryCard
                        text="Add Completion Action"
                        icons={addCompletionActionCardIcons ?? []}
                        onPress={onAddCompletionActionPressed}
                        testID="add-completion-action"
                      />
                    ) : null}
                  </FadeInView>
                </>
              )}
            </>
          )}
        </Boundary>
      </QuestPlaylistProvider>
    </QuestViewContainer>
  );
};
export const QuestView = React.memo(_QuestView, isEqual);
QuestView.displayName = "QuestView";
const QuestInstanceStatusMessage: React.FC<{
  behavior: StatusMessageBehavior;
}> = ({ behavior }) => {
  let statusMessage;
  switch (behavior) {
    case StatusMessageBehavior.AwaitingSubmission:
      statusMessage = "Waiting for submission";
      break;
    case StatusMessageBehavior.AwaitingReview:
      statusMessage = "Waiting for review";
      break;
    case StatusMessageBehavior.AwaitingOthersReview:
      statusMessage = "Waiting for other reviews";
      break;
    default:
      return null;
  }

  return (
    <StyledStatusMessageView>
      <Icon icon={"info"} />
      <Text>{statusMessage}</Text>
    </StyledStatusMessageView>
  );
};

const StyledStatusMessageView = styled.View`
  height: 24px;
  flex-direction: row;
  align-items: center;
  align-self: center;
`;

const StyledItemPlaceholder = styled.View`
  height: 100%;
  width: 100%;
`;

const StyledSubmitButton = styled(Button)`
  margin-vertical: 16px;
`;

const Spacer = styled.View`
  height: 16px;
`;

const ItemRowWrapper = styled.View`
  margin-bottom: 20px;
`;

const QuestViewContainer = styled.View`
  padding-horizontal: 20px;
`;

export const QuestSectionDivider = styled.View`
  width: 1px;
  height: 98px;
  margin-top: 24px;
  margin-bottom: 18px;
  align-self: center;
  background-color: ${({ theme }) => theme.questView.sectionDivider};
`;
