import Loader from "@app/components/animated/loader";
import { FiltersProps } from "@app/components/filters";
import { ChooseEntry } from "@app/components/modal/chooseEntry";
import PlaceholderView from "@app/components/screen/PlaceholderView";
import { useAppNavigation, useAppRoute } from "@app/navigation/QMNavigator";
import { groupTemplatesByWorkspace } from "@app/util/groupTemplatesByWorkspace";
import React, { useCallback, useState } from "react";
import { Platform } from "react-native";
import styled from "styled-components/native";
import { fetchWorkspaces } from "@app/util/client/requests/workspaces";
import { fetchQuests } from "@app/util/client/requests/quests";
import { createLink } from "@app/util/link.utils";
import { useSimpleListItemRenderer } from "@app/components/questkit/SimpleListItem";
import { useRequest } from "@app/util/client/requests";

export const QuestListScreen: React.FC = () => {
  const navigation = useAppNavigation<"QuestList">();
  const activeFilters = useAppRoute<"QuestList">().params?.filters;
  const setActiveFilters = (
    updateFunction: (
      prevFilters: FiltersProps["activeFilters"]
    ) => FiltersProps["activeFilters"]
  ) => {
    const nextFilters = updateFunction(activeFilters);
    navigation.setParams({
      filters: nextFilters,
    });
  };

  const includeArchived = Object.keys(activeFilters)
    .filter(
      (activeFilterKey) => activeFilters[activeFilterKey].type === "status"
    )
    .find(
      (activeFilterKey) => activeFilters[activeFilterKey].id === "archived"
    );

  const [searchText, setSearchText] = useState("");

  // Load data
  const {
    data: workspacesData,
    error: workspacesError,
    isValidating: workspacesIsValidating,
    refresh: workspacesRefresh,
  } = useRequest(fetchWorkspaces());

  const {
    data: templatesData,
    error: templatesError,
    isValidating: templatesIsValidating,
    refresh: templatesRefresh,
  } = useRequest(
    fetchQuests(includeArchived ? { include_archived: "true" } : {})
  );

  const onSelectBuilder = useCallback((questId: string) => {
    return createLink({
      screen: "Quest",
      params: {
        questId: questId,
        screen: "QuestRuns",
      },
    });
  }, []);
  const renderItem = useSimpleListItemRenderer("standard", onSelectBuilder);

  if (!templatesData || !workspacesData) {
    if (templatesError || workspacesError) {
      return (
        <StyledSafeAreaView>
          <PlaceholderView
            text="Oops, that didn't quite work."
            actions={[
              {
                type: "primary",
                text: "Reload",
                loading: templatesIsValidating || workspacesIsValidating,
                onPress: () => {
                  if (templatesError) {
                    void templatesRefresh();
                  }
                  if (workspacesError) {
                    void workspacesRefresh();
                  }
                },
              },
            ]}
            key={"placeholderViewError"}
          />
        </StyledSafeAreaView>
      );
    } else {
      return (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      );
    }
  }

  const activeWorkspaceFilterIds = Object.keys(activeFilters)
    .filter(
      (activeFilterKey) => activeFilters[activeFilterKey].type === "workspace"
    )
    .map((activeFilterKey) => activeFilters[activeFilterKey].id);

  const filteredTemplates = templatesData
    .filter((template) => {
      // Filter for selected workspaces
      const workspace = template.workspace || {
        key: "__private__",
        id: "private",
        name: "Personal",
      };
      if (activeWorkspaceFilterIds.length > 0) {
        return activeWorkspaceFilterIds.indexOf(workspace.id) !== -1;
      } else {
        return true;
      }
    })
    .filter((template) => {
      // Filter for searchString
      if (searchText && searchText.length > 0) {
        return template
          .currentQuestPrototype!.name.toLowerCase()
          .includes(searchText.toLowerCase());
      } else {
        return true;
      }
    });

  const { templatesByWorkspaceId } =
    groupTemplatesByWorkspace(filteredTemplates);

  const sectionsData = (
    templatesByWorkspaceId["private"] &&
    templatesByWorkspaceId["private"].length > 0
      ? [
          {
            key: "__personal__",
            title: "Personal",
            data: templatesByWorkspaceId["private"].map(
              (templatesByWorkspaceIdEntry) => ({
                id: templatesByWorkspaceIdEntry.id,
                key: templatesByWorkspaceIdEntry.id,
                name: templatesByWorkspaceIdEntry.currentQuestPrototype!.name,
              })
            ),
          },
        ]
      : []
  ).concat(
    workspacesData
      .filter(
        (workspace) =>
          workspace.id !== "private" && templatesByWorkspaceId[workspace.id]
      )
      .map((workspace) => ({
        key: workspace.id,
        title: workspace.name,
        data: templatesByWorkspaceId[workspace.id].map(
          (templatesByWorkspaceIdEntry) => ({
            id: templatesByWorkspaceIdEntry.id,
            key: templatesByWorkspaceIdEntry.id,
            name: templatesByWorkspaceIdEntry.currentQuestPrototype!.name,
          })
        ),
      }))
  );

  return (
    <TemplatesScreenWrapper
      behavior={Platform.OS === "ios" ? "padding" : "height"}
    >
      <ChooseEntry
        renderItem={renderItem}
        search={{
          searchPlaceholder: "Search Quests",
          searchText: searchText,
          setSearchText: setSearchText,
        }}
        filters={{
          activeFilters: activeFilters,
          setActiveFilters: setActiveFilters,
          availableFilters: {
            status: [
              {
                name: "Archived",
                id: "archived",
                type: "status",
              },
            ],
            workspace: workspacesData.map((workspace) => ({
              name: workspace.name,
              id: workspace.id,
              type: "workspace",
            })),
          },
          filterTypes: {
            status: {
              name: "Status",
              icon: "filter",
            },
            workspace: {
              name: "Workspace",
              icon: "group",
            },
            template: {
              name: "Quest",
              icon: "item",
            },
          },
        }}
        sectionsData={sectionsData}
      />
    </TemplatesScreenWrapper>
  );
};

const TemplatesScreenWrapper = styled.KeyboardAvoidingView`
  flex: 1;
`;

const LoaderWrapper = styled.View`
  background-color: ${({ theme }) => theme.background};
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const StyledSafeAreaView = styled.SafeAreaView`
  flex: 1;
`;
