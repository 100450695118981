import Loader from "@app/components/animated/loader";
import { FiltersProps } from "@app/components/filters";
import { ChooseEntry } from "@app/components/modal/chooseEntry";
import PlaceholderView from "@app/components/screen/PlaceholderView";
import { QMStackParamList } from "@app/navigation/QMNavigator";
import { StackScreenProps } from "@react-navigation/stack";
import React, { useCallback, useState } from "react";
import { Platform } from "react-native";
import styled from "styled-components/native";
import _ from "lodash";
import { fetchWorkspaces } from "@app/util/client/requests/workspaces";
import { fetchAssignments } from "@app/util/client/requests/assignments";
import { createLink } from "@app/util/link.utils";
import { useSimpleListItemRenderer } from "@app/components/questkit/SimpleListItem";
import { useRequest } from "@app/util/client/requests";

export const AssignmentsScreen: React.FC<
  StackScreenProps<QMStackParamList, "Assignments">
> = (props) => {
  const [activeTab, setActiveTab] = useState<"open" | "completed">("open");

  const activeFilters = props.route.params.filters;
  const setActiveFilters = (
    updateFunction: (
      prevFilters: FiltersProps["activeFilters"]
    ) => FiltersProps["activeFilters"]
  ) => {
    const nextFilters = updateFunction(activeFilters);
    props.navigation.setParams({
      filters: nextFilters,
    });
  };

  const [searchText, setSearchText] = useState("");

  const {
    data: workspaceListData,
    error: workspaceListError,
    isValidating: workspaceListIsValidating,
    refresh: workspaceListRefresh,
  } = useRequest(fetchWorkspaces());

  const {
    data: assignmentListData,
    error: assignmentListError,
    isValidating: assignmentListIsValidating,
    refresh: assignmentListRefresh,
  } = useRequest(
    fetchAssignments(
      activeTab === "open" ? ["OPEN", "IN_REVIEW"] : ["COMPLETED"]
    )
  );

  const onSelectBuilder = useCallback((instanceId: string) => {
    return createLink({
      screen: "QuestInstance",
      params: {
        id: instanceId,
      },
    });
  }, []);

  const activeTemplateFilterIds = Object.keys(activeFilters)
    .filter(
      (activeFilterKey) => activeFilters[activeFilterKey].type === "template"
    )
    .map((activeFilterKey) => activeFilters[activeFilterKey].id);

  const filteredAssignments = (assignmentListData || []).filter(
    (assignment) => {
      // Filter for selected template
      if (activeTemplateFilterIds.length > 0) {
        return (
          activeTemplateFilterIds.indexOf(assignment.formPrototype.quest.id) !==
          -1
        );
      } else {
        return true;
      }
    }
  );

  const filteredAssignmentsWithSearchApplied = (
    filteredAssignments || []
  ).filter((assignment) => {
    // Filter for searchString
    if (searchText && searchText.length > 0) {
      return assignment.formPrototype.name
        .toLowerCase()
        .includes(searchText.toLowerCase());
    } else {
      return true;
    }
  });
  const renderItem = useSimpleListItemRenderer("assignment", onSelectBuilder);

  if (!assignmentListData || !workspaceListData) {
    if (assignmentListError || workspaceListError) {
      return (
        <StyledSafeAreaView>
          <PlaceholderView
            text="Oops, that didn't quite work."
            actions={[
              {
                type: "primary",
                text: "Reload",
                loading:
                  assignmentListIsValidating || workspaceListIsValidating,
                onPress: () => {
                  if (assignmentListError) {
                    void assignmentListRefresh();
                  }
                  if (workspaceListError) {
                    void workspaceListRefresh();
                  }
                },
              },
            ]}
            key={"placeholderViewError"}
          />
        </StyledSafeAreaView>
      );
    } else {
      return (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      );
    }
  }

  const sectionsDataByStartedAt = _.groupBy(
    filteredAssignmentsWithSearchApplied.map((assignment) => ({
      id: assignment.formInstance.id,
      key: assignment.formInstance.id,
      name: assignment.formPrototype.name,
      startedAt: assignment.formInstance.startedAt,
    })),
    ({ startedAt }) =>
      startedAt
        ? new Date(startedAt).toLocaleDateString(undefined, {
            weekday: "long",
            year: "numeric",
            month: "short",
            day: "numeric",
          })
        : "undefined"
  );

  const sectionsData = Object.keys(sectionsDataByStartedAt).map((startedAt) => {
    return {
      key: startedAt,
      title: startedAt === "undefined" ? undefined : startedAt,
      data: sectionsDataByStartedAt[startedAt],
    };
  });

  return (
    <QuestsScreenWrapper
      behavior={Platform.OS === "ios" ? "padding" : "height"}
    >
      <ChooseEntry
        tabStyle="MULTI_SWITCH"
        renderItem={renderItem}
        search={{
          searchPlaceholder: "Search Assignments",
          searchText: searchText,
          setSearchText: setSearchText,
        }}
        placeholder={
          <PlaceholderView
            text={
              assignmentListData.length === 0
                ? activeTab === "open"
                  ? "Inbox Zero! 🤩"
                  : "No completed assignments."
                : "No results."
            }
            actions={
              assignmentListData.length === 0
                ? []
                : [
                    {
                      type: "primary",
                      text:
                        activeTab === "open"
                          ? `${
                              assignmentListData.length === 0
                                ? "Show"
                                : "Search"
                            } completed assignments`
                          : `${
                              assignmentListData.length === 0
                                ? "Show"
                                : "Search"
                            } open assignments`,
                      onPress: () => {
                        if (activeTab === "open") {
                          setActiveTab("completed");
                        } else {
                          setActiveTab("open");
                        }
                      },
                    },
                  ]
            }
          />
        }
        tabs={{
          activeTab: activeTab,
          setActiveTab: setActiveTab,
          availableTabs: [
            {
              name: "Open",
              id: "open",
            },
            {
              name: "Completed",
              id: "completed",
            },
          ],
        }}
        filters={{
          activeFilters: activeFilters,
          setActiveFilters: setActiveFilters,
          availableFilters: {},
          filterTypes: {
            template: {
              name: "Quest",
              icon: "item",
            },
          },
        }}
        sectionsData={sectionsData}
      />
    </QuestsScreenWrapper>
  );
};

const QuestsScreenWrapper = styled.KeyboardAvoidingView`
  background-color: ${({ theme }) => theme.background};
  flex: 1;
`;

const LoaderWrapper = styled.View`
  background-color: ${({ theme }) => theme.background};
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const StyledSafeAreaView = styled.SafeAreaView`
  flex: 1;
`;
