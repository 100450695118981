import { ScaledSize } from "react-native/Libraries/Utilities/Dimensions";
import { useAppSelector } from "@app/store";
import { selectLoggedInUser } from "@app/store/cache/users";
import { useWindowDimensions } from "react-native";
import { InitIntercom, updateIntercom } from "@app/chat/Intercom";
import { useEffect } from "react";
import {
  CombinedRoutesParamList,
  useFocusedRoute,
} from "@app/navigation/QMNavigator";

const INTERCOM_POSITION_OVERRIDE: Partial<
  Record<keyof CombinedRoutesParamList, (dimensions: ScaledSize) => number>
> = {
  PublicTemplatePreview: () => 100,
  // QuestTemplate: () => 100,
  QuestRuns: (dimensions) => (dimensions.width < 640 ? 70 : 0),
};

export function useIntercom() {
  const loggedInUser = useAppSelector(selectLoggedInUser);
  const focusedRoute = useFocusedRoute();
  const dimensions = useWindowDimensions();
  const intercomChatVerticalPadding =
    INTERCOM_POSITION_OVERRIDE[
      focusedRoute?.name as keyof CombinedRoutesParamList
    ]?.(dimensions) ?? 0;
  if (loggedInUser) {
    InitIntercom({
      vertical_padding: intercomChatVerticalPadding,
      user_id: loggedInUser.id,
      name: loggedInUser.displayName,
      ...(loggedInUser.email ? { email: loggedInUser.email } : {}),
      ...(loggedInUser.avatarSmallUrl
        ? {
            avatar: {
              type: "avatar",
              image_url: loggedInUser.avatarSmallUrl,
            },
          }
        : {}),
    });
  }
  useEffect(() => {
    updateIntercom({ verticalPadding: intercomChatVerticalPadding });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intercomChatVerticalPadding]);
}
