import { SideBarItem } from "@app/navigation/sideBar/items/SideBarItem";
import * as React from "react";
import { useCallback, useMemo } from "react";
import { CombinedRoutesParamList } from "@app/navigation/QMNavigator";

interface SideBarWorkspaceQuestItemProps {
  id: string;
  name: string;
  isActiveOverride: boolean | undefined;
}

export const SideBarWorkspaceQuestItem: React.FC<
  SideBarWorkspaceQuestItemProps
> = ({ id, name, isActiveOverride }) => {
  const screenParams = useMemo(
    (): CombinedRoutesParamList["Quest"] => ({
      questId: id,
      screen: "QuestRuns",
    }),
    [id]
  );
  const screenDifferentiator = useCallback(
    (screenParams: CombinedRoutesParamList["Quest"]) => screenParams?.questId,
    []
  );
  return (
    <SideBarItem<"Quest", CombinedRoutesParamList["Quest"]>
      label={name}
      screenName={"Quest"}
      screenParams={screenParams}
      isActiveOverride={isActiveOverride}
      getSameScreenDifferentiatorValue={screenDifferentiator}
    />
  );
};
