import React, { useEffect, useState } from "react";
import styled from "styled-components/native";
import Loader from "@app/components/animated/loader";
import { useAppNavigation, useAppRoute } from "@app/navigation/QMNavigator";
import { StackActions } from "@react-navigation/native";
import PlaceholderView from "@app/components/screen/PlaceholderView";
import { updateTemplates } from "@app/util/cacheMod";
import { sentry } from "@app/util/sentry";
import { createQuest } from "@app/util/client/requests/quests";

export const TemplateCreate: React.FC = () => {
  const navigation = useAppNavigation();
  const params = useAppRoute<"TemplateCreate">().params || {};

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  const createTemplate = () => {
    setLoading(true);

    createQuest({
      name: params.name,
      workspaceId: params.workspaceId,
      sourceTemplateId: params.sourceTemplateId,
      sourcePrototypeId: params.sourcePrototypeId,
    })
      .then((newQuest) => {
        void updateTemplates();
        navigation.dispatch(
          StackActions.replace("Quest", {
            templateId: newQuest.id,
            screen: "QuestEdit",
            params: {
              questPrototypeId: newQuest.currentQuestPrototype!.id,
            },
          })
        );
      })
      .catch((e) => {
        console.error(e);
        sentry.captureException(e, {
          extra: {
            message: "Failed to create Quest",
            params,
          },
        });
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    createTemplate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  if (error) {
    return (
      <PlaceholderView
        text="Oops, that didn't quite work."
        actions={[
          {
            type: "primary",
            text: "Try again",
            loading: loading,
            onPress: createTemplate,
          },
        ]}
      />
    );
  }

  return (
    <StyledView>
      <Loader />
    </StyledView>
  );
};

const StyledView = styled.View`
  background-color: ${({ theme }) => theme.background};
  flex: 1;
  justify-content: center;
  align-items: center;
`;
