import React, { useContext, useState } from "react";
import styled from "styled-components/native";
import Button from "@app/components/questkit/button";
import HeaderText from "@app/components/questkit/headerText";
import TextInput from "@app/components/questkit/textInput";
import { View } from "react-native";
import {
  NEW_WORKSPACE_ID,
  WorkspaceList,
} from "@app/components/questkit/WorkspaceList";
import {
  SnackbarContext,
  SnackbarSeverity,
} from "@app/components/snackbar/SnackbarContext";
import { useAppNavigation } from "@app/navigation/QMNavigator";
import { updateTemplates } from "@app/util/cacheMod";
import { useAppSelector } from "@app/store";
import { createQuest } from "@app/util/client/requests/quests";
import { NEW_TEAM_ID, TeamList } from "@app/components/questkit/TeamList";
import { selectLoggedInUser } from "@app/store/cache/users";
import { selectWorkspaceById } from "@app/store/cache/workspaces";
import { usePromise } from "@app/util/usePromise";
import { sentry } from "@app/util/sentry";
import { createTeam } from "@app/util/client/requests/teams";
import { createWorkspace } from "@app/util/client/requests/workspaces";
import { CreateWorkspaceRequest } from "@questmate/openapi-spec";
import { MODAL_CLOSE_DELAY } from "@app/components/modal";
import { ENV } from "@app/config/env";

interface CreateQuestProps {
  setShowModal: (showModal: boolean) => void;
  createButtonText: string;
  sourceTemplateId?: string;
  defaultName?: string;
}

const TEAMS_ENABLED = ENV.featureFlags.enableTeams;

const CreateQuest: React.FC<CreateQuestProps> = ({
  setShowModal,
  createButtonText,
  sourceTemplateId,
  defaultName,
}) => {
  const navigation = useAppNavigation();
  const initialWorkspaceId = useAppSelector(
    (state) => state.ui.sideBar.activeWorkspaceId
  );

  const snackbar = useContext(SnackbarContext);

  const [questName, setQuestName] = useState(defaultName || "");
  const [workspaceId, setWorkspaceId] = useState<string | null>(
    initialWorkspaceId
  );

  const [questCreated, setQuestCreated] = useState(false);

  const { execute: onCreateQuest, isLoading } = usePromise(async () => {
    const createQuestFields = {
      name: questName,
      workspaceId,
      sourceTemplateId,
    };

    if (!createQuestFields.name.trim()) {
      snackbar.sendMessage(`Please enter a name.`, SnackbarSeverity.WARNING);
      return;
    }
    if (TEAMS_ENABLED) {
      if (createQuestFields.workspaceId === NEW_WORKSPACE_ID) {
        if (!newWorkspaceName.trim()) {
          snackbar.sendMessage(
            `Please provide a name for the new workspace.`,
            SnackbarSeverity.WARNING
          );
          return;
        } else if (!teamIdForNewWorkspace) {
          snackbar.sendMessage(
            `Please select a team for the new workspace.`,
            SnackbarSeverity.WARNING
          );
          return;
        } else if (
          teamIdForNewWorkspace === NEW_TEAM_ID &&
          !newTeamName.trim()
        ) {
          snackbar.sendMessage(
            `Please provide a name for the new team.`,
            SnackbarSeverity.WARNING
          );
          return;
        }
        const createWorkspaceFields = {
          name: newWorkspaceName,
        } as CreateWorkspaceRequest;
        try {
          const newTeam = await createTeam({ name: newTeamName });
          createWorkspaceFields.teamId = newTeam.id;
        } catch (e) {
          snackbar.sendMessage(
            `Failed to create new Team. Try again later.`,
            SnackbarSeverity.WARNING
          );
          sentry.captureException(e, { extra: { newTeamName } });
          return;
        }
        try {
          const newWorkspace = await createWorkspace(createWorkspaceFields);
          createQuestFields.workspaceId = newWorkspace.id;
        } catch (e) {
          snackbar.sendMessage(
            `Failed to create new Workspace. Try again later.`,
            SnackbarSeverity.WARNING
          );
          sentry.captureException(e, { extra: { createWorkspaceFields } });
          // This ensures if they try again they will not create another new team
          setTeamIdForNewWorkspace(createWorkspaceFields.teamId);
          return;
        }
      }
    }

    return createQuest(createQuestFields)
      .then((newQuest) => {
        void updateTemplates();
        setQuestCreated(true);
        setTimeout(() => {
          setShowModal(false);
          navigation.navigate("Quest", {
            questId: newQuest.id,
            screen: "QuestEdit",
            params: {
              questPrototypeId: newQuest.currentQuestPrototype!.id,
            },
          });
        }, MODAL_CLOSE_DELAY);
      })
      .catch((e) => {
        snackbar.sendMessage(
          `Failed to create Quest. Please try again later.`,
          SnackbarSeverity.WARNING
        );
        sentry.captureException(e, {
          extra: {
            questName,
            workspaceId,
          },
        });
      });
  });

  // TODO: FINISH_TEAMS_FEATURE - Start of teams related hooks
  const [newWorkspaceName, setNewWorkspaceName] = useState("Main");
  const usersDisplayName = useAppSelector(
    (state) => selectLoggedInUser(state)?.displayName
  );
  const initialTeamId = useAppSelector((state) => {
    const workspaceId = state.ui.sideBar.activeWorkspaceId;
    if (!workspaceId) {
      return undefined;
    }
    return selectWorkspaceById(state, workspaceId)?.teamId || undefined;
  });
  const [teamIdForNewWorkspace, setTeamIdForNewWorkspace] = useState<
    string | undefined
  >(initialTeamId);
  const [newTeamName, setNewTeamName] = useState(
    usersDisplayName ? `${usersDisplayName}'s Team` : ""
  );
  // TODO: FINISH_TEAMS_FEATURE - End of teams related hooks

  return (
    <CreateQuestDialog>
      <View onStartShouldSetResponder={() => true}>
        <HeaderText icon="item" title="Quest Name" />
        <StyledTextInput
          onChangeText={setQuestName}
          value={questName}
          placeholder={"Awesome Quest"}
          autoFocus={true}
          editable={!isLoading && !questCreated}
          onSubmitEditing={onCreateQuest}
        />
        <HeaderText icon="group" title="Workspace" />
        <WorkspaceListOption
          setWorkspaceId={setWorkspaceId}
          workspaceId={workspaceId}
          allowCreate={TEAMS_ENABLED}
        />
        {TEAMS_ENABLED && workspaceId === NEW_WORKSPACE_ID ? (
          <>
            <StyledTextInput
              onChangeText={setNewWorkspaceName}
              value={newWorkspaceName}
              placeholder={"Workspace Name"}
              autoFocus={true}
              editable={!isLoading && !questCreated}
            />
            <HeaderText icon="group" title="Team" />
            <TeamList
              teamId={teamIdForNewWorkspace}
              setTeamId={setTeamIdForNewWorkspace}
              allowCreate={true}
            />
            {teamIdForNewWorkspace === NEW_TEAM_ID ? (
              <StyledTextInput
                onChangeText={setNewTeamName}
                value={newTeamName}
                placeholder={"Team Name"}
                autoFocus={true}
                editable={!isLoading && !questCreated}
              />
            ) : null}
          </>
        ) : null}
        <StyledCreateQuestButton
          onPress={onCreateQuest}
          success={questCreated}
          loading={isLoading}
          title={createButtonText}
        />
      </View>
    </CreateQuestDialog>
  );
};

const CreateQuestDialog = styled.ScrollView`
  padding-horizontal: 12px;
  padding-vertical: 10px;
`;

const StyledCreateQuestButton = styled(Button)`
  align-self: center;
  margin-bottom: 24px;
  width: 100%;
`;

const StyledTextInput = styled(TextInput)`
  margin-bottom: 31px;
`;

const WorkspaceListOption = styled(WorkspaceList)`
  margin-bottom: 31px;
`;

export default CreateQuest;
