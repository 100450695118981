import PlaceholderView from "@app/components/screen/PlaceholderView";
import { apiRequest } from "@app/util/client";
import React, { useEffect, useState } from "react";
import { Platform, Pressable } from "react-native";
import Loader from "@app/components/animated/loader";
import styled from "styled-components/native";
import { Analytics } from "@app/analytics";
import { RevealText } from "@app/components/questkit/revealText";
import Button from "@app/components/questkit/button";
import Text from "@app/components/questkit/text";
import { MultiSwitch } from "@app/components/MultiSwitch";
import { useAppNavigation, useAppRoute } from "@app/navigation/QMNavigator";
import { visitLink } from "@app/util/link.utils";
import { sentry } from "@app/util/sentry";
import { SpecialRibbon } from "@app/components/SpecialRibbon";
import {
  fetchBillingPlans,
  fetchBillingSummary,
} from "@app/util/client/requests/billing";
import { useRequest } from "@app/util/client/requests";

type BillingInterval = "year" | "month";

const BillingIntervalTextMap: Record<BillingInterval, string> = {
  year: "yearly",
  month: "monthly",
};

export const BillingScreen: React.FC = () => {
  if (Platform.OS !== "web") {
    /**
     * Another failsafe to avoid accidentally rendering this on mobile.
     * It should already be impossible to navigate here though.
     */
    throw new Error("BillingScreen is only available on web.");
  }

  const [chargeInterval, setChargeInterval] = useState<BillingInterval>("year");
  const route = useAppRoute<"Billing">();
  const coupon = route.params?.coupon;
  const navigation = useAppNavigation<"Billing">();

  const { data, error, refresh, isValidating } = useRequest(
    fetchBillingSummary(),
    {
      revalidateOnReconnect: true,
      shouldRetryOnError: false,
      refreshInterval: 10000,
    }
  );

  const {
    data: plansData,
    error: plansError,
    refresh: plansRefresh,
    isValidating: plansIsValidating,
  } = useRequest(fetchBillingPlans({ coupon }), {
    shouldRetryOnError: false,
  });

  const availableIntervals = plansData?.plans
    ?.flatMap((plan) => plan.prices.map((price) => price.interval))
    .filter(
      (value, index, self) => self.indexOf(value) === index
    ) as BillingInterval[];
  useEffect(() => {
    if (
      availableIntervals &&
      availableIntervals.length > 0 &&
      !availableIntervals.includes(chargeInterval)
    ) {
      setChargeInterval(availableIntervals[0] as BillingInterval);
    }
  }, [availableIntervals, chargeInterval]);

  const [loading, setLoading] = useState(false);

  if (error && !data) {
    return (
      <StyledView>
        <PlaceholderView
          text="Oops, that didn't quite work."
          actions={[
            {
              type: "primary",
              text: "Reload",
              loading: isValidating,
              onPress: refresh,
            },
          ]}
        />
      </StyledView>
    );
  }

  if (!data) {
    return (
      <StyledView>
        <Loader />
      </StyledView>
    );
  }

  if (data.subscriptionStatus === "INACTIVE") {
    if (plansError && !data) {
      return (
        <StyledView>
          <PlaceholderView
            text="Oops, that didn't quite work."
            actions={[
              {
                type: "primary",
                text: "Reload",
                loading: plansIsValidating,
                onPress: plansRefresh,
              },
            ]}
          />
        </StyledView>
      );
    }

    if (!plansData) {
      return (
        <StyledView>
          <Loader />
        </StyledView>
      );
    }

    return (
      <PlansContainer>
        <RevealText
          text={
            plansData.headerTextOverride ||
            // "Supercharge your workflows, today. 🚀"
            // "Get things done, faster. 🚀"
            // "Get sh*t done, like never before. 🚀"
            // "Get more done, faster. 🚀"
            // "Get things done, 10x faster. 🚀"
            // "Get done everything, 10x faster. 🚀"
            // "Move faster, 10x. 🚀"
            // "Outwork everyone, 10x. 🚀"
            // "10x your productivity. 🚀"
            // "Get 10x more productive. 🚀"
            // "Empower your team, achieve 10x results. 🚀"
            // "Get things done at hyper-speed."
            // "Start getting things done like magic."
            // "Getting things done never felt so good 😇"
            "Make a thousand times feel like once."
            // "Active the cheat code for getting things done. 🕹️"
            // "The 10x productivity cheat code. 🕹️"
            // "The 10x productivity cheat code for you and your team. 🕹️"
            // "Getting things done that feels like cheating. 😇"
            // "Join the #1 app for getting things done."
            // "Active the cheat code for getting things done. 🕹️"
            // "Where AI Meets GSD: Unleashing Unmatched Efficiency. 🤖"
            // "Get anything done, 10x as easy & fast. Guaranteed. 🤘"
          }
          style={{ paddingVertical: 40 }}
        />
        {coupon ? (
          <Pressable
            onPress={() => {
              navigation.setParams({ coupon: undefined });
            }}
            style={{ marginBottom: availableIntervals.length > 1 ? 20 : 0 }}
          >
            <Text size="mediumBold" $underlined>
              Show all plans
            </Text>
          </Pressable>
        ) : null}
        {availableIntervals.length > 1 && (
          <MultiSwitch
            options={availableIntervals.map(
              (interval) => BillingIntervalTextMap[interval]
            )}
            selectedOption={BillingIntervalTextMap[chargeInterval]}
            onChange={(option) => {
              for (const [key, value] of Object.entries(
                BillingIntervalTextMap
              )) {
                if (value === option) {
                  setChargeInterval(key as BillingInterval);
                  return;
                }
              }
            }}
          />
        )}
        <Plans>
          {plansData.plans.map((plan) => {
            const price = plan.prices.find(
              (price) => price.interval === chargeInterval
            );
            if (!price) {
              return null;
            } else {
              return (
                <PlanContainer key={plan.id}>
                  <PlanName size="large">
                    {plan.name.replace("Questmate ", "").replace(" Plan", "")}
                  </PlanName>
                  <PriceContainer>
                    {price.discount && "discountType" in price.discount ? (
                      <>
                        <Text
                          size="mediumBold"
                          $inactive={true}
                          style={{ textDecorationLine: "line-through" }}
                        >
                          {(chargeInterval === "year"
                            ? price.discount.originalAmount / 12
                            : price.discount.originalAmount
                          ).toLocaleString(undefined, {
                            currency: price.currency,
                            style: "currency",
                            maximumFractionDigits: 0,
                            minimumFractionDigits: 0,
                          })}
                          <Text $inactive={true} size={"medium"}>
                            {price.perUser ? "/user" : "/team"}
                          </Text>
                        </Text>
                      </>
                    ) : null}
                    <PlanPrice size="xlarge">
                      {(chargeInterval === "year"
                        ? price.amount / 12
                        : price.amount
                      ).toLocaleString(undefined, {
                        currency: price.currency,
                        style: "currency",
                        maximumFractionDigits: 0,
                        minimumFractionDigits: 0,
                      })}
                      <PlanPrice size={"large"}>
                        {price.perUser ? "/user" : "/team"}
                      </PlanPrice>
                    </PlanPrice>
                    <Text>
                      per month
                      {chargeInterval === "year" ? " billed yearly" : ""}
                    </Text>
                  </PriceContainer>
                  <PlanFeatures>
                    {plan.features.map((feature, index) => (
                      <PlanFeature key={index}>
                        <Text size="mediumBold">✨</Text>
                        <Text size="mediumBold">{feature.text}</Text>
                      </PlanFeature>
                    ))}
                  </PlanFeatures>
                  <SubscriptionButton
                    title={
                      price.hasTrialPeriod ? "Start free trial" : "Start now"
                    }
                    price={price.id}
                    couponId={coupon}
                  />
                  {price.discount && "discountType" in price.discount ? (
                    <SpecialRibbon
                      color={({ theme }) => theme.button.warning.background}
                    >
                      <Text size="large" $inverted={true}>
                        {price.discount.discountType === "PERCENTAGE"
                          ? `${price.discount.percentOff * 100}% OFF`
                          : `$${price.discount.amountOff} OFF`}
                      </Text>
                    </SpecialRibbon>
                  ) : null}
                </PlanContainer>
              );
            }
          })}
        </Plans>
      </PlansContainer>
    );
  } else if (
    data.subscriptionStatus === "ACTIVE" ||
    data.subscriptionStatus === "PAST_DUE"
  ) {
    const text =
      data.subscriptionStatus === "ACTIVE"
        ? `You're On ${
            data.subscriptionType
              ? `The ${
                  data.subscriptionType === "PERSONAL" ? "Personal" : "Team"
                }`
              : "A"
          } Plan - Awesome!`
        : "Oops! Your latest payment failed. Let's get it fixed.";
    return (
      <PlaceholderView
        text={text}
        actions={[
          {
            type: "primary",
            text: "Manage Subscription",
            loading: loading,
            onPress: () => {
              setLoading(true);
              Analytics.trackEvent("Open Subscription Management Page");
              apiRequest<{ url: string }>(
                "post",
                "/internal/billing/stripe/customerPortal/session"
              )
                .then((result) => {
                  visitLink(result.url);
                })
                .catch((error) => {
                  console.error(
                    "Failed to open manage subscription page",
                    error
                  );
                  sentry.captureException(error);
                  setLoading(false);
                });
            },
          },
        ]}
      />
    );
  } else {
    return (
      <StyledView>
        <PlaceholderView
          text="Oops, that didn't quite work."
          actions={[
            {
              type: "primary",
              text: "Reload",
              loading: isValidating,
              onPress: refresh,
            },
          ]}
        />
      </StyledView>
    );
  }
};

type SubscriptionButtonProps = {
  title: string;
  price: string;
  couponId: string | undefined;
};

const SubscriptionButton: React.FC<SubscriptionButtonProps> = ({
  title,
  price,
  couponId,
}) => {
  const [loading, setLoading] = useState(false);

  return (
    <Button
      title={title}
      style={{ width: "100%" }}
      onPress={() => {
        setLoading(true);
        Analytics.trackEvent("Start New Subscription Checkout Session");
        const urlParams = new URLSearchParams();
        if (couponId) {
          urlParams.append("coupon", couponId);
        }
        apiRequest<{ url: string }>(
          "post",
          `/internal/billing/stripe/checkout/session?${urlParams.toString()}`,
          { price }
        )
          .then((result) => {
            visitLink(result.url);
          })
          .catch((error) => {
            console.error(
              "Failed to start new subscription checkout session",
              error
            );
            sentry.captureException(error);
            setLoading(false);
          });
      }}
      loading={loading}
    />
  );
};

const PlansContainer = styled.ScrollView.attrs({
  contentContainerStyle: {
    alignItems: "center",
  },
})``;

const Plans = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 100%;
  padding: 30px;
`;

const PlanName = styled(Text)``;

const PlanPrice = styled(Text)`
  color: ${({ theme }) => theme.interaction.primary};
`;

const PriceContainer = styled.View`
  align-items: center;
  flex-direction: column;
`;

const PlanFeatures = styled.View`
  width: 100%;
  padding-horizontal: 40px;
  padding-vertical: 40px;
  justify-content: center;
  gap: 20px;
`;

const PlanFeature = styled.View`
  flex-direction: row;
  gap: 10px;
`;

const PlanContainer = styled.View`
  position: relative;
  align-items: center;
  background-color: ${({ theme }) => theme.card.background};
  padding-vertical: 40px;
  padding-horizontal: 20px;
  border-radius: 10px;
  margin-vertical: 20px;
  margin-horizontal: 20px;
  min-width: 400px;
  max-width: 540px;
  height: 660px;
  flex: 1;
  justify-content: space-between;
`;

const StyledView = styled.View`
  background-color: ${({ theme }) => theme.background};
  flex: 1;
  justify-content: center;
  align-items: center;
`;
